import React, { useCallback, useRef } from "react";
import platform from "platform";
import Button from "components/Button";
import "./styles.scss";
import ImageLoading from "components/ImageLoading";
import { useContext } from '../../Context';
interface RoboPartType {
    name?: string
    image?: string
    link?: string
    onUpgrade?: () => void
    onSwap?: () => void
};

const Upgradeable = (props: RoboPartType) => {
    const isMobileDevice = platform?.os?.family === 'iOS' || platform?.os?.family === 'Android';
    return isMobileDevice? <UpgradeableMobile {...props} />: <UpgradeableDesktop {...props} />;
};

const Swapable = (props: RoboPartType) => {
    const isMobileDevice = platform?.os?.family === 'iOS' || platform?.os?.family === 'Android';
    return isMobileDevice? <SwapableMobile {...props} />: <SwapableDesktop {...props} />;
};

const SwapableDesktop = ({name, image, link, onSwap}: RoboPartType) => {
    /**
     * Using adjacent sibling selector produces an ugly effect when mouse moves over the image. That's why the button layer is shown using javascript.
     * https://www.w3schools.com/howto/howto_css_display_element_hover.asp
     */

    const swapDivRef: any = useRef(null);

    const onMouseOver = useCallback(() => {
        if (swapDivRef.current) {
            swapDivRef.current.style.display = "block";
        }
    }, []);
    const onMouseOut = useCallback(() => {
        if (swapDivRef.current) {
            swapDivRef.current.style.display = "none";
        }
    }, []);

    const handleOnSwap = () => {
       onSwap && onSwap();
    };

    return (<div
                className="robopart"
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
            >
                <div  className="layer-upgrade" style={{position:"relative", display:"inline-block"}}>
                    <ImageLoading src={image}/>
                    <div ref={swapDivRef} style={{display: "none"}}>
                        <div className="overlay d-flex align-items-center justify-content-center">
                            <Button label='SWAP' className="upgrade" onClick={handleOnSwap}/>
                        </div>
                    </div>
                </div>
                <a href={link} target="_blank" rel="noreferrer"><p className="px-3 pt-1 text-center">{name}</p></a>
            </div>
        );
};


const UpgradeableDesktop = ({name, image, link, onUpgrade}: RoboPartType) => {
    /**
     * Using adjacent sibling selector produces an ugly effect when mouse moves over the image. That's why the button layer is shown using javascript.
     * https://www.w3schools.com/howto/howto_css_display_element_hover.asp
     */

    const upgradeDivRef: any = useRef(null);

    const onMouseOver = useCallback(() => {
        if (upgradeDivRef.current) {
            upgradeDivRef.current.style.display = "block";
        }
    }, []);
    const onMouseOut = useCallback(() => {
        if (upgradeDivRef.current) {
            upgradeDivRef.current.style.display = "none";
        }
    }, []);

    const handleOnUpgrade = () => {
       onUpgrade && onUpgrade();
    };

    return (<div
                className="robopart"
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
            >
                <div  className="layer-upgrade" style={{position:"relative", display:"inline-block"}}>
                    <ImageLoading src={image}/>
                    <div ref={upgradeDivRef} style={{display: "none"}}>
                        <div className="overlay d-flex align-items-center justify-content-center">
                            <Button label='UPGRADE' className="upgrade" onClick={handleOnUpgrade}/>
                        </div>
                    </div>
                </div>
                <a href={link} target="_blank" rel="noreferrer"><p className="px-3 pt-1 text-center">{name}</p></a>
            </div>
        );
};

const SwapableMobile = ({name, image, link, onSwap}: RoboPartType) => {
    const { state: {spaceRobotSelectedOnInventoryMobile}, dispatch } = useContext();

    const showSwap = spaceRobotSelectedOnInventoryMobile === name;

    const handleOnClick = useCallback(() => {
        dispatch({
            type: "setSpaceRobotSelectedOnInventoryMobile",
            spaceRobotName: (spaceRobotSelectedOnInventoryMobile != name)? name: undefined
        });
    }, [spaceRobotSelectedOnInventoryMobile]);

    const handleOnSwap = () => {
       onSwap && onSwap();
    };

    return (<div
                className="robopart"
                onClick={handleOnClick}
            >
                <div  className="layer-upgrade" style={{position:"relative", display:"inline-block"}}>
                    <ImageLoading src={image}/>
                    {showSwap &&
                        <div>
                            <div className="overlay d-flex align-items-center justify-content-center">
                                <Button label='SWAP' className="upgrade" onClick={handleOnSwap}/>
                            </div>
                        </div>
                    }
                </div>
                <a href={link} target="_blank" rel="noreferrer"><p className="px-3 pt-1 text-center">{name}</p></a>
            </div>
        );
};


const UpgradeableMobile = ({name, image, link, onUpgrade}: RoboPartType) => {
    const { state: {spaceRobotSelectedOnInventoryMobile}, dispatch } = useContext();

    const showUpgrade = spaceRobotSelectedOnInventoryMobile === name;

    const handleOnClick = useCallback(() => {
        dispatch({
            type: "setSpaceRobotSelectedOnInventoryMobile",
            spaceRobotName: (spaceRobotSelectedOnInventoryMobile != name)? name: undefined
        });
    }, [spaceRobotSelectedOnInventoryMobile]);

    const handleOnUpgrade = () => {
       onUpgrade && onUpgrade();
    };

    return (<div
                className="robopart"
                onClick={handleOnClick}
            >
                <div  className="layer-upgrade" style={{position:"relative", display:"inline-block"}}>
                    <ImageLoading src={image}/>
                    {showUpgrade &&
                        <div>
                            <div className="overlay d-flex align-items-center justify-content-center">
                                <Button label='UPGRADE' className="upgrade" onClick={handleOnUpgrade}/>
                            </div>
                        </div>
                    }
                </div>
                <a href={link} target="_blank" rel="noreferrer"><p className="px-3 pt-1 text-center">{name}</p></a>
            </div>
        );
};

const NotUpgradeable = ({name, image, link}: RoboPartType) => {
    return (<div className="robopart">
                <div className="roboImage" >
                    <ImageLoading src={image}/>
                </div>
                <a href={link} target="_blank" rel="noreferrer"><p className="px-3 pt-1 text-center">{name}</p></a>
            </div>);
};

const RoboPart = ({name, image, link, onUpgrade, onSwap}: RoboPartType) => {
    const isUpgradeable = Boolean(onUpgrade);
    const isSwapable = Boolean(onSwap);
    if (isUpgradeable) {
        return <Upgradeable
            name={name}
            image={image}
            link={link}
            onUpgrade={onUpgrade}
            onSwap={onSwap}
            />;}
        else if (isSwapable){
            return  <Swapable
            name={name}
            image={image}
            link={link}
            onUpgrade={onUpgrade}
            onSwap={onSwap}
            />;}
        else { return <NotUpgradeable
            name={name}
            image={image}
            link={link}
            />;};
};

export default RoboPart;