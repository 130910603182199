import React, { useState, useEffect } from "react";
import { collections, network } from "config";
import { NFTType, getAccountNfts } from "../../cli/ElrondAPI";
import "./styles.scss";
import { ContextProvider } from "./Context";
import { useDispatch } from "context";
import Upgrade from "./Upgrade";
import Wallet, { Connect } from "components/Wallet";
import Claim from "components/Claim";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";

const UpgradeMobile = () => {
  const { address } = useGetAccountInfo();
  const [accountNFTs, setAccountNFTs] = useState<Array<NFTType>>([]);
  const dispatch = useDispatch();

  async function fetchNFTs(
    accountAddress: string,
    collectionIds: string[],
  ): Promise<Array<NFTType>> {
    const { data: NFTs } = await getAccountNfts({
      apiAddress: network.apiAddress,
      address: accountAddress,
      timeout: 3000,
      collections: collectionIds,
    });

    return NFTs ?? [];
  }

  useEffect(() => {
    if (address) {
      const collectionIds = collections.map((c) => c.identifier);
      fetchNFTs(address, collectionIds).then((nfts) => {
        setAccountNFTs(nfts);
      });
    }
  }, [address]);

  const handleOnClickConnect = () => {
    dispatch({ type: "showLogin" });
  };

  return (
    <ContextProvider initialState={{ roboPartsLocked: {} }}>
      <Claim title="UPGRADE">
        <img src="/assets/beta.png" className="mb-4" style={{ width: "45%" }} />
        <p className="mt-4 text-center">
          <strong>*/: NFT Upgrade protocol //:</strong>
        </p>
        <span className="text-center">
          By activating this protocol, you can Upgrade your Space Robots NFTs
          using your RoboParts.
        </span>
      </Claim>
      {address ? (
        <Wallet address={address} />
      ) : (
        <Connect
          className="mt-5 mb-5 mb-md"
          onClickConnect={handleOnClickConnect}
          page="inventory"
        />
      )}
      {address && (
        <div className="container inventory-container px-4">
          <h2
            className="pb-2 mb-2 mt-5"
            style={{ borderBottom: "1px solid #ffffff", lineHeight: "28.8px" }}
          >
            UPGRADE
          </h2>
          <Upgrade nfts={accountNFTs} />
        </div>
      )}
    </ContextProvider>
  );
};

export default UpgradeMobile;
