import React from "react";
import { dAppName } from "config";
import withPageTitle from "./components/PageTitle";
import Home from "./pages/Home";
import Inventory from "./pages/Inventory";
import MobileTemplates from "./pages/Inventory/templates-mobile";
import MobileUpgrade from "./pages/Inventory/upgrade-mobile";
import MobileWarehouse from "./pages/Inventory/warehouse-mobile";
import SwapMobile from "pages/Inventory/swap-mobile";

export const routeNames = {
  home: "/",
  unlock: "/unlock",
  ledger: "/ledger",
  walletconnect: "/walletconnect",
  unlockpack: "/unlockpack",
  inventory: "/inventory",
  upgradeDesktop: "/upgrade",
  swapDesktop: "/swap",
  swapMobile: "/mobile/swap",
  upgrade: "/mobile/upgrade",
  templates: "/mobile/templates",
  warehouse: "/mobile/inventory",
};

const routes = [
  {
    path: routeNames.home,
    title: "Unlock",
    component: Home,
  },
  {
    path: routeNames.unlock,
    title: "Unlock",
    component: Home
  },
  {
    path: routeNames.unlockpack,
    title: "Unlock",
    component: Home,
  },
  {
    path: routeNames.inventory,
    title: "Inventory",
    component: Inventory,
    background: "tower-background",
  },
  {
    path: routeNames.upgradeDesktop,
    title: "Upgrade",
    component: Inventory,
    background: "tower-background",
  },
  {
    path: routeNames.swapDesktop,
    title: "Swap",
    component: Inventory,
    background: "tower-background"
  },
  {
    path: routeNames.swapMobile,
    title: "Swap",
    component: SwapMobile,
    background: "tower-background"
  },
  {
    path: routeNames.upgrade,
    title: "Upgrade",
    component: MobileUpgrade,
    background: "tower-background",
  },
  {
    path: routeNames.templates,
    title: "Templates",
    component: MobileTemplates,
    background: "tower-background",
  },
  {
    path: routeNames.warehouse,
    title: "Inventory",
    component: MobileWarehouse,
  },
];

export function getBackgroundForRoute(path: string): string | void {
  const [route] = routes.filter((item) => item.path == path);
  return route.background;
}

const wrappedRoutes = () =>
  routes.map((route) => {
    const title = route.title
      ? `${route.title} • MultiversX ${dAppName}`
      : `MultiversX ${dAppName}`;
    return {
      path: route.path,
      component: withPageTitle(title, route.component),
    };
  });

export default wrappedRoutes();
