import Button from "components/Button";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

interface JollyPackType {
  visible?: boolean;
  onHide?: any;
}

const JollyPack = ({onHide, visible}: JollyPackType) => {
  const [show, setShow] = useState(visible);

  useEffect(()=>{
     setShow(visible);
  },[visible]);

  const handleClose = () => {
    setShow(false);
    if (!!onHide) onHide();
  };

    return (
      <Modal 
        className="robopack-parts"
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="text-center">
          <div className="container align-center"><h3>Happy Holidays!</h3><h4>Gifts will be distributed after Christmas</h4></div>
        </Modal.Header>
        <Modal.Footer className="d-flex justify-content-center pb-3">
            <Button label="READ MORE" className="button-confirm" href='https://blog.spacerobotsclub.com/jollypack/' target="_blank"/>
        </Modal.Footer>

      </Modal>
  );
    };

export default JollyPack;