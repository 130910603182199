import { NFTType } from "cli/ElrondAPI";
import { StateType, MessageType } from "./state";

export type ActionType =
    | { type: "showLogin" | "hideLogin" }
    | {
        type: "showMessage";
        title?: MessageType["title"];
        text?: MessageType["text"];
        danger?: MessageType["danger"];
        onConfirm?: MessageType["onConfirm"];
        onCancel?: MessageType["onCancel"];
        onHide?: MessageType["onCancel"];
    }
    | { type: "hideMessage" }
    | { type: "setSpaceRobot"; spaceRobot: NFTType; }

export function reducer(state: StateType, action: ActionType) {
    switch (action.type) {
        case "showLogin": {
            const newState = {
                ...state,
                showLogin: !state.showLogin
            };
            return newState;
        }
        case "hideLogin": {
            const newState = {
                ...state,
                showLogin: false
            };
            return newState;
        }
        case "showMessage": {
            const newState = {
                ...state,
                message: {
                    show: true,
                    title: action.title,
                    text: action.text,
                    danger: action.danger,
                    onConfirm: action.onConfirm,
                    onCancel: action.onCancel,
                    onHide: action.onHide,
                }
            };
            return newState;
        }
        case "hideMessage": {
            const newState = {
                ...state,
                message: {
                    show: false
                }
            };
            return newState;
        }
        case "setSpaceRobot": {
            const newState = {
                ...state,
                spaceRobot: action.spaceRobot
            };
            return newState;
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`);
        }
    }
}
