import React, { useState, useEffect } from "react";
import Tabs from "components/Tabs";
import Templates from "components/Templates";
import { collections, network } from "config";
import { NFTType, getAccountNfts } from "../../cli/ElrondAPI";
import "./styles.scss";
import { ContextProvider } from "./Context";
import useResponsive from "hooks/useResponsive";
import Upgrade from "./Upgrade";
import Swap from "./Swap";
import Warehouse from "./Warehouse";
import Claim from "components/Claim";
import Wallet, { Connect } from "components/Wallet";
import Button from "components/Button";
import { useDispatch } from "context";
import { routeNames } from "routes";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";

const TAB_INVENTORY = 'Inventory';
const TAB_UPGRADE = 'Upgrade';
const TAB_SWAP = 'Swap';
const tabs = [TAB_INVENTORY, TAB_UPGRADE, TAB_SWAP];

const Inventory = () => {
  const TAB_ROUTES = {
    [routeNames.inventory]: tabs.indexOf(TAB_INVENTORY),
    [routeNames.upgradeDesktop]: tabs.indexOf(TAB_UPGRADE),
  };
  const forceTab = TAB_ROUTES[location.pathname];
  const [currentTab, setCurrentTab] = useState(forceTab ?? 0);
  const { address } = useGetAccountInfo();
  const [accountNFTs, setAccountNFTs] = useState<Array<NFTType>>([]);

  const dispatch = useDispatch();
  const { isMobile } = useResponsive();

  async function fetchNFTs(
    accountAddress: string,
    collectionIds: string[],
  ): Promise<Array<NFTType>> {
    const { data: NFTs } = await getAccountNfts({
      apiAddress: network.apiAddress,
      address: accountAddress,
      timeout: 3000,
      collections: collectionIds,
    });

    return NFTs ?? [];
  }

  const handleOnChangeTab = (index: number) => {
    setCurrentTab(index);

    if (currentTab === 0) {
      setClaim(upgradeClaim);
    } else {
      setClaim(myInventoryClaim);
    }
  };

  const handleOnUpgrade = () => {
    setCurrentTab(tabs.indexOf(TAB_UPGRADE));
  };

    const handleOnSwap = () => {
        setCurrentTab(tabs.indexOf(TAB_SWAP));
    };
    
    const myInventoryClaim = () => {
        return (
            <Claim title="MY INVENTORY">
                <p className="mt-4 text-center"><strong>*/: The Inventory //:</strong></p>
                <span className="text-center">Data storage to manage Space Robots items collected.</span>
            </Claim>
        );
    };
    
    const upgradeClaim = () => {
        return (
            <Claim title="UPGRADE">
                <img src="/assets/beta.png" className="mb-4" style={{width: "15%"}}/>
                <p className="mt-4 text-center"><strong>*/: NFT Upgrade protocol //:</strong></p>
                <span className="text-center">By activating this protocol, you can Upgrade your Space Robots NFTs using your RoboParts.</span>
            </Claim>
        );
    };
    const [claim, setClaim] = useState(myInventoryClaim);

  useEffect(() => {
    if (address) {
      const collectionIds = collections.map((c) => c.identifier);
      fetchNFTs(address, collectionIds).then((nfts) => {
        setAccountNFTs(nfts);
      });
    }
  }, [address]);

  const handleOnClickConnect = () => {
    dispatch({ type: "showLogin" });
  };

  return (
    <>
      {claim}
      {address ? (
        <Wallet address={address} />
      ) : (
        <Connect
          className="mt-5 mb-5 mb-md"
          onClickConnect={handleOnClickConnect}
          page="inventory"
        />
      )}
      <ContextProvider initialState={{ roboPartsLocked: {} }}>
        {address && 
          <div className="inventory container-fluid mt-5">
            <div className="container inventory-container py-4 ps-5 pe-5">
              <Tabs
                initialTab={currentTab}
                tabs={tabs}
                onChange={handleOnChangeTab}
              />
                            {currentTab == 0 && <Warehouse nfts={accountNFTs} onUpgrade={handleOnUpgrade} onSwap={handleOnSwap}/>}
                            {currentTab == 1 && <Upgrade nfts={accountNFTs} />}
                            {currentTab == 2 && <Swap nfts={accountNFTs} />}
                            </div>
                        { currentTab != 2 &&
                        <div className="mt-4">
                            <Templates address={address} />
                        </div>
                        }
                    </div>
                }

            </ContextProvider>
        </>
    );
};

export default Inventory;
