import React, { useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import "./styles.scss";
import Button from "../Button";
import Video from "../Video";
import { jolly_pack_360 } from "config";

interface MessageType {
    title?: string;
    text?: string;
    show: boolean;
    danger?: boolean;
    onHide: any;
    onConfirm?: any;
    onCancel?: any;
}

const Message = ({title, text, show, onHide, danger, onConfirm, onCancel}: MessageType)=>{
    const [confirming, setConfirming] = React.useState(false);

    const classNames = ["message"];
    if (danger) classNames.push("danger");

    const ref = React.useRef(null);

    useEffect(() => {
        setConfirming(false);
    }, [show, text]);

    const handleOnConfirm = () => {
        if (onConfirm) {
            setConfirming(true);
            onConfirm();
        }
    };

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    };

    const handleOnHide = () => {
        setConfirming(false);
        if (onHide) {
            onHide();
        }
    };

    if (text?.startsWith('The Swap was successful')) {
        return (
            <Modal 
            ref={ref} 
            show={show} 
            onHide={handleOnHide} 
            className={classNames.join(" ")} 
            aria-labelledby="contained-modal-title-vcenter"
            size="xl"
            centered
            >
                <Modal.Header closeButton={!Boolean(onCancel)} />
                <Modal.Body className="text-center">
                    
                    <div>
                        <h5>The Swap was successful. You have received a JollyPack!</h5>
                        <h5>Please check Unlock page</h5>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center pb-3">
                    {confirming && <Spinner animation="border"variant="light"/>}
                    {!confirming && !!onConfirm && <Button label="CONTINUE" className="button-confirm" onClick={handleOnConfirm}/>}
                    {!confirming && !!onCancel && <Button label="DECLINE" className="button-confirm" onClick={handleOnCancel}/>}
                    <div className="container">
                    <div className="jolly-bot">
                        <br/>
                            <Video video={jolly_pack_360} loop={true}/>
                    </div>
                    </div>
                </Modal.Footer>
            </Modal>

        );
    } else {
        return (
            <Modal 
            ref={ref} 
            show={show} 
            onHide={handleOnHide} 
            className={classNames.join(" ")} 
            aria-labelledby="contained-modal-title-vcenter"
            size="xl"
            centered
            >
                <Modal.Header closeButton={!Boolean(onCancel)} />
                <Modal.Body className="text-center">
                    <Modal.Title><h3>{title}</h3></Modal.Title>
                    <div>
                        <h5>{text}</h5>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center pb-4">
                    {confirming && <Spinner animation="border"variant="light"/>}
                    {!confirming && !!onConfirm && <Button label="CONTINUE" className="button-confirm me-md-5" onClick={handleOnConfirm}/>}
                    {!confirming && !!onCancel && <Button label="DECLINE" className="button-confirm" onClick={handleOnCancel}/>}
                </Modal.Footer>
            </Modal>

        );
    }
};

export default Message;