import React from "react";
import "./styles.scss";

interface ButtonType {
  label?: string;
  onClick?: any;
  className?: string;
  background?: string;
  href?: string;
  target?: string;
};

const Button = ({label, onClick, className, background, href, target}: ButtonType)=>{
  const _className = "button" + (className?` ${className}`:"");
  
  let style = {};
  if (background) {
    style = {backgroundColor: background};
  };

  function handleOnClick(e: React.MouseEvent) {
      if (!!onClick) {
        e.preventDefault();
        onClick();
      }
  }
  return (
      <a href={href||"#"} className={_className} onClick={handleOnClick} style={style} target={target}>
          {label}
      </a>
  );
};

export default Button;