import { sendTransactions } from "@multiversx/sdk-dapp/services/transactions/sendTransactions";
import { Address } from "@multiversx/sdk-core/out";
import to from "await-to-js";
import { contractAddress, contractAddress2, contractAddress3, contractAddressJolly, gasLimit } from "config";

import NFTIdentifier from "./NFTIdentifier";

export class ContractSpaceRobots {
  public static async unlock(
    identifier: string,
    sender: string,
    units: number,
    suffix?: string,
  ) {
    let method = '';
    let contract = '';
    if (suffix == 'x') {
      method = "open_phx_box";
      contract = contractAddress2;
    }
    else if (suffix == 'cake') {
      method = "open_box_1";
      contract = contractAddress3;
    }
    else {
      method = "open_box" + (suffix ? `_${suffix}` : "");
      contract = contractAddress;
    }
    
    const NFTId = new NFTIdentifier(identifier);
    const collection = Buffer.from(NFTId.getCollection()).toString("hex");
    const nonce = NFTId.getNonce();

    let quantity = units.toString(16);
    quantity = quantity.length % 2 == 0 ? quantity : "0" + quantity;

    const callingMethod = Buffer.from(method).toString("hex");

    const params: any = {
      transactions: [
        {
          receiver: sender,
          sender,
          data: `ESDTNFTTransfer@${collection}@${nonce}@${quantity}@${new Address(
            contractAddress,
          ).hex()}@${callingMethod}`,
          value: "0",
          gasLimit: gasLimit,
        },
      ],
    };

    const [err, transactionResponse] = await to(sendTransactions(params));

    if (err) {
      return Promise.reject(err);
    }

    return transactionResponse;
  }
  public static async swap(
    identifier: string,
    sender: string,
    units: number,
  ) {
    const method = "swap_jolly_bot";
    const NFTId = new NFTIdentifier(identifier);
    const collection = Buffer.from(NFTId.getCollection()).toString("hex");
    const nonce = NFTId.getNonce();

    let quantity = units.toString(16);
    quantity = quantity.length % 2 == 0 ? quantity : "0" + quantity;

    const callingMethod = Buffer.from(method).toString("hex");

    const params: any = {
      transactions: [
        {
          receiver: sender,
          sender,
          data: `ESDTNFTTransfer@${collection}@${nonce}@${quantity}@${new Address(
            contractAddressJolly,
          ).hex()}@${callingMethod}`,
          value: "0",
          gasLimit: gasLimit,
        },
      ],
    };

    const [err, transactionResponse] = await to(sendTransactions(params));

    if (err) {
      return Promise.reject(err);
    }

    return transactionResponse;
  }
  
  public static async send_jolly_pack_to_owner(
    identifier: string,
    sender: string,
    units: number,
  ) {
    const method = "send_jolly_pack_to_owner";
    const NFTId = new NFTIdentifier(identifier);
    const collection = Buffer.from(NFTId.getCollection()).toString("hex");
    const nonce = NFTId.getNonce();

    let quantity = units.toString(16);
    quantity = quantity.length % 2 == 0 ? quantity : "0" + quantity;

    const callingMethod = Buffer.from(method).toString("hex");

    const params: any = {
      transactions: [
        {
          receiver: sender,
          sender,
          data: `ESDTNFTTransfer@${collection}@${nonce}@${quantity}@${new Address(
            contractAddressJolly,
          ).hex()}@${callingMethod}`,
          value: "0",
          gasLimit: gasLimit,
        },
      ],
    };

    const [err, transactionResponse] = await to(sendTransactions(params));

    if (err) {
      return Promise.reject(err);
    }

    return transactionResponse;
  }
}
