import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as RemoveIcon } from "assets/img/remove.svg";
import { getNfts, NFTType } from "cli/ElrondAPI";
import { deleteTemplate, getTemplates } from "cli/TemplateAPI";
import Button from "components/Button";
import Desktop from "components/Desktop";
import Mobile from "components/Mobile";
import RobotComposition from "components/RobotComposition";
import Slider from "components/Slider";
import Traits from "components/Traits";
import {
  collectionRoboParts,
  collectionSpaceRobots,
  network,
  templateApiAddress,
} from "config";
import { useDispatch } from "context";
import { routeNames } from "routes";
import "./styles.scss";
import { getAttackDefenseByRoboPart } from "utils";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";

interface TemplatesPropsType {
  address: string;
}

const Templates = ({ address }: TemplatesPropsType) => {
  const [templates, setTemplates] = useState<
    Array<{
      id?: string;
      spaceRobotNFT?: NFTType;
      roboPartsNFT: Array<NFTType>;
    }>
  >([]);
  const { address: loggedIn } = useGetAccountInfo();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function fetchTemplates(address: string) {
    const rawTemplates = await getTemplates(address);
    return Promise.all(
      rawTemplates.map(async (template) => {
        let spaceRobotNFT: NFTType | undefined;
        let roboPartsNFT: Array<NFTType> = [];

        const { data } = await getNfts({
          apiAddress: network.apiAddress,
          identifiers: [template.spaceRobot, ...template.roboParts],
          timeout: 3000,
        });
        if (data) {
          spaceRobotNFT = data.filter(
            (nft) => nft.collection == collectionSpaceRobots.identifier,
          )[0]; // data[0];
          roboPartsNFT = data.filter(
            (nft) => nft.collection == collectionRoboParts.identifier,
          ); // data.slice(1);
        }
        return {
          id: template.id,
          spaceRobotNFT,
          roboPartsNFT,
        };
      }),
    );
  }

  useEffect(() => {
    if (loggedIn) {
      fetchTemplates(address).then((accountTemplates) => {
        setTemplates(accountTemplates);
      });
    }
  }, [address]);

  function removeTemplate(template: any) {
    deleteTemplate({ address, templateId: template.id }).then(() => {
      dispatch({
        type: "showMessage",
        title: "Template deleted successfully",
        text: `The template of ${template.spaceRobotNFT.name} has been deleted successfully`,
        onHide: () => {
          navigate(location.pathname);
        },
      });
    });
  }

  const handleOnClickRemove = async (template: any, e: React.MouseEvent) => {
    e.preventDefault();
    dispatch({
      type: "showMessage",
      text: `Are you sure that you want to delete the template of ${template.spaceRobotNFT.name}?`,
      onConfirm: () => removeTemplate(template),
      onCancel: () => {
        dispatch({ type: "hideMessage" });
      },
    });
  };

  function buildSlides(): Array<JSX.Element> {
    const rowsBySlide = 3;
    const items: Array<JSX.Element> = [];
    for (let i = 0; i < templates.length; i += rowsBySlide) {
      const slide = templates.slice(i, i + rowsBySlide).map((template, key) => {
        const { spaceRobotNFT, roboPartsNFT } = template;
        const templateAttackDefense = getAttackDefenseByRoboPart({
          spaceRobot: spaceRobotNFT,
          roboPartsUpgrade: roboPartsNFT,
        });
        const attack = Object.values(templateAttackDefense).reduce(
          (acc, curr) => (curr.attack ?? 0) + acc,
          0,
        );
        const defense = Object.values(templateAttackDefense).reduce(
          (acc, curr) => (curr.defense ?? 0) + acc,
          0,
        );

        return (
          <div key={key} className="col-12 text-center pt-4">
            <div
              key={spaceRobotNFT?.identifier}
              className="space-robot"
              style={{ position: "relative", display: "inline-block" }}
            >
              <a href="#" onClick={(e) => handleOnClickRemove(template, e)}>
                <RemoveIcon className="remove-icon" />
              </a>
              <a
                href={`${templateApiAddress}/public/${address}/${template.id}.png`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="download-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                </div>
              </a>
              {spaceRobotNFT && (
                <RobotComposition
                  spaceRobot={spaceRobotNFT}
                  roboParts={roboPartsNFT}
                />
              )}
            </div>
            <p className="title mb-2">{spaceRobotNFT?.name}</p>
            <Traits attack={attack} defense={defense} />
          </div>
        );
      });

      items.push(<>{slide}</>);
    }

    return items;
  }

  if (!templates || templates.length == 0) {
    return <></>;
  }

  return (
    <div className="container templates pt-4 pt-md">
      <Desktop>
        <h2 className="text-center">SAVED</h2>
        <div className="row">
          {templates &&
            templates.map((template, i) => {
              const { spaceRobotNFT, roboPartsNFT } = template;
              const templateAttackDefense = getAttackDefenseByRoboPart({
                spaceRobot: spaceRobotNFT,
                roboPartsUpgrade: roboPartsNFT,
              });
              const attack = Object.values(templateAttackDefense).reduce(
                (acc, curr) => (curr.attack ?? 0) + acc,
                0,
              );
              const defense = Object.values(templateAttackDefense).reduce(
                (acc, curr) => (curr.defense ?? 0) + acc,
                0,
              );
              return (
                <div key={i} id={template.id} className="col text-center pb-5">
                  <div
                    key={i}
                    className="space-robot"
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <a
                      href="#"
                      onClick={(e) => handleOnClickRemove(template, e)}
                    >
                      <RemoveIcon className="remove-icon" />
                    </a>
                    <a
                      href={`${templateApiAddress}/public/${address}/${template.id}.png`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="download-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                          />
                        </svg>
                      </div>
                    </a>
                    {spaceRobotNFT && (
                      <RobotComposition
                        spaceRobot={spaceRobotNFT}
                        roboParts={roboPartsNFT}
                      />
                    )}
                  </div>
                  <p className="title pt-3">{spaceRobotNFT?.name}</p>
                  <Traits attack={attack} defense={defense} />
                </div>
              );
            })}
        </div>
      </Desktop>
      <Mobile>
        <div className="text-center">
          <Button
            className="button-back-upgrade"
            label="BACK TO UPGRADE"
            href={routeNames.upgrade}
          />
        </div>
        <div className="mt-2 pb-4">
          <Slider items={buildSlides()} perView={2} breakpoints={{}} />
        </div>
      </Mobile>
    </div>
  );
};

export default Templates;
