import React from "react";
import SocialLink from "components/SocialLink";
import discord from "../../../assets/img/discord.png";
import telegram from "../../../assets/img/telegram.png";
import twitter from "../../../assets/img/twitter.png";

const Footer = () => {
  return (
    <footer className="ps-3 pe-3" >
      <div className="row pt-3 pb-3">
        <div className="col d-flex justify-content-start align-items-center">
            <span>&copy; 2022 SpaceRobots. All rights reserved.</span>
        </div>
        <div className="col d-flex justify-content-end align-items-center">
          <SocialLink link="https://discord.gg/spacerobots" image={discord} title="Discord" />
          <SocialLink link="https://twitter.com/SpaceRobotsNFT" image={twitter} title="Twitter" />
          <SocialLink link="https://t.me/spacerobots" image={telegram} title="Telegram" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
