import React from "react";
import { useContext, useDispatch } from "context";
import useResponsive from "hooks/useResponsive";
import routes, { getBackgroundForRoute, routeNames } from "routes";
import Button from "../Button";
import Claim from "../Claim";
import Message from "../Message";
import Wallet, { Connect } from "../Wallet";
import AppNav from "./AppNav";
import Footer from "./Footer";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import LoginModal from "components/LoginModal";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { address } = useGetAccountInfo();
  const { message, showLogin } = useContext();
  const dispatch = useDispatch();
  const { isMobile } = useResponsive();

  function handleHideLogin() {
    dispatch({ type: "hideLogin" });
  }

  function handleHideMessage() {
    dispatch({ type: "hideMessage" });
    message.onHide && message.onHide();
  }

  const handleOnClickConnect = () => {
    dispatch({ type: "showLogin" });
  };

  const background =
    getBackgroundForRoute(location.pathname) ?? "main-background";
  return (
    <div className={`main ${background} d-flex flex-column flex-fill wrapper`}>
      <AppNav />
      <main className="d-flex flex-column flex-grow-1 space-robots">
        <LoginModal
          show={!address && (showLogin || false)}
          handleClose={handleHideLogin}
        />

        <Message
          show={!!message && message.show}
          title={message?.title}
          text={message?.text}
          onHide={handleHideMessage}
          danger={message?.danger}
          onConfirm={message?.onConfirm}
          onCancel={message?.onCancel}
        />
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
