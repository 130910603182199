import React from "react";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import { logout } from "@multiversx/sdk-dapp/utils/logout";
import "./styles.scss";

interface UnloggedType {
  onClickConnect?: any;
  className?: string;
  page: string;
}
const Connect = ({ className, onClickConnect, page }: UnloggedType) => {
  return (
    <div className={className}>
      <div className="row">
        <div className="col text-center">
          <Button label="CONNECT TO WALLET" onClick={onClickConnect} />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col text-center">
          {page === "unlock" && (<>
            <p>To unlock your RoboPack first connect your MultiversX wallet.</p>
            </>
          )}
          {page === "inventory" && (
            <p>{"//"}: To start first connect your MultiversX wallet.</p>
          )}
        </div>
      </div>
    </div>
  );
};

interface WalletType {
  address: string;
  className?: string;
}
const Wallet = ({ className, address }: WalletType) => {
  const navigate = useNavigate();

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault();
    logout(`${window.location.origin}/`);
    navigate("/");
  };

  const addressFormated = address.slice(0, 6) + "...." + address.slice(-6);
  return (
    <div className={className}>
      <h3 className="mb-5">MULTIVERSX WALLET</h3>
      <div className="container d-flex justify-content-center">
        <div className="d-flex justify-content-center align-items-center framed-address">
          <span className="me-4">{addressFormated}</span>
          <a href="#" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} size="lg" title="Logout" />
          </a>
        </div>
      </div>
    </div>
  );
};

export { Connect };

export default Wallet;
