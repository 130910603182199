import React, { Fragment } from "react";
import {
  Navbar,
  Nav,
  Container
} from "react-bootstrap";
import discord from "assets/img/discord.png";
import telegram from "assets/img/telegram.png";
import twitter from "assets/img/twitter.png";
import SocialLink from "components/SocialLink";
import { routeNames} from "routes";

const NavLinkDivider = () => {
  const style = {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "14px",
    color: "#FFFFFF"
  };

  return (<span className="d-none d-lg-block" style={style} >|</span>);
};

const menu = [
  {
    href: `${routeNames.upgradeDesktop}`,
    label: "UPGRADE",
    desktop: true
  },
  {
    href: `${routeNames.swapDesktop}`,
    label: "SWAP",
    desktop: true
  },
  {
    href: routeNames.unlockpack,
    label: "UNLOCK",
    isRoot: true
  },
  {
    href: routeNames.inventory,
    label: "INVENTORY",
    desktop: true
  },
  {
    href: routeNames.warehouse,
    label: "INVENTORY",
    mobile: true
  },
  {
    href: `${routeNames.upgrade}`,
    label: "UPGRADE",
    mobile: true
  },
  {
    href: `${routeNames.swapMobile}`,
    label: "SWAP",
    mobile: true
  },
  {
    href: `${routeNames.templates}`,
    label: "TEMPLATES",
    mobile: true
  },
  {
    href: "https://spacerobots.club/",
    label: "PROJECT"
  }
];

const Items = () => {
  return (
     <>
      {
        menu.map(({href, isRoot, label, mobile, desktop}, i) => {

          let className = "mt-2 mt-lg-0";
          className = `${className} ${mobile? 'd-sm-none': ''}`;
          className = `${className} ${desktop? 'd-none d-sm-block': ''}`;
          return (
            <Fragment key={i}>
              <Nav.Link
                active={(href == location.pathname) || (isRoot && location.pathname == "/")}
                className={className}
                href={href}
              >
                {label}
              </Nav.Link>
              {(i< (menu.length -1) && !mobile) && <NavLinkDivider />}
            </Fragment>
          );}
        )
      }
    </>
  );
};

const AppNav = () => {
  return (
    <Navbar collapseOnSelect expand="lg" >
      <Container fluid>
        <Navbar.Brand href="https://spacerobotsclub.com" className="text-white">
          <svg width="233" height="16" viewBox="0 0 233 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.6022 0.154419H21.597V1.4126H27.6022C30.1339 1.4126 31.286 3.49226 31.286 5.42378C31.286 6.47804 30.9444 7.49575 30.352 8.21333C29.692 9.01172 28.7407 9.43497 27.6022 9.43497H22.174C22.0061 9.43497 21.8459 9.50037 21.7282 9.61965C21.6105 9.73701 21.543 9.89669 21.543 10.0641V15.3796H22.805V10.6912H27.6041C29.1324 10.6912 30.4195 10.1102 31.3265 9.01173C32.1157 8.05751 32.5499 6.78393 32.5499 5.42186C32.548 2.80353 30.8498 0.154419 27.6022 0.154419Z" fill="white"/>
            <path d="M122.1 9.07901C122.906 8.1248 123.352 6.84737 123.352 5.48338C123.352 2.86505 121.619 0.215942 118.308 0.215942H112.166V1.47412H118.308C120.907 1.47412 122.092 3.55378 122.092 5.48338C122.092 6.5511 121.752 7.53995 121.137 8.26716C120.457 9.0694 119.481 9.49264 118.308 9.49264H112.754C112.407 9.49264 112.123 9.77352 112.123 10.1217V15.4373H113.385V10.7489H118.31C118.416 10.7489 118.522 10.7451 118.626 10.7393L122.372 15.3834L123.354 14.5966L120.033 10.4776C120.839 10.2102 121.54 9.73889 122.1 9.07901Z" fill="white"/>
            <path d="M67.3716 13.4557C66.0864 12.8593 63.9213 11.3433 63.8345 7.88045C63.9503 5.23711 65.3165 3.11512 67.5839 2.05509C69.9284 0.958512 72.7825 1.2721 74.8511 2.84964L75.6056 1.86656C73.1337 -0.0187954 69.857 -0.374705 67.0551 0.933499C64.3478 2.19938 62.7211 4.7196 62.5898 7.84967C62.5898 7.86313 62.5898 7.8766 62.5898 7.89006C62.6593 10.9201 64.2108 13.3576 66.8448 14.5792C67.9968 15.114 69.2492 15.3738 70.49 15.3738C72.356 15.3738 74.1969 14.7831 75.6172 13.6442L74.8376 12.6785C72.8674 14.2618 69.7953 14.5792 67.3716 13.4557Z" fill="white"/>
            <path d="M140.239 0.0332031C136.007 0.0332031 132.564 3.46531 132.564 7.68426C132.564 11.9032 136.007 15.3353 140.239 15.3353C144.471 15.3353 147.913 11.9032 147.913 7.68426C147.913 3.46531 144.471 0.0332031 140.239 0.0332031ZM140.239 14.0618C136.711 14.0618 133.844 11.201 133.844 7.68619C133.844 4.16943 136.713 1.3087 140.239 1.3087C143.766 1.3087 146.634 4.16943 146.634 7.68619C146.634 11.201 143.764 14.0618 140.239 14.0618Z" fill="white"/>
            <path d="M185.471 0.0505371C181.249 0.0505371 177.814 3.47495 177.814 7.68429C177.814 11.8936 181.249 15.318 185.471 15.318C189.694 15.318 193.128 11.8936 193.128 7.68429C193.127 3.47687 189.692 0.0505371 185.471 0.0505371ZM185.471 14.0791C181.934 14.0791 179.057 11.2107 179.057 7.68429C179.057 4.15791 181.934 1.28948 185.471 1.28948C189.009 1.28948 191.886 4.15791 191.886 7.68429C191.884 11.2107 189.007 14.0791 185.471 14.0791Z" fill="white"/>
            <path d="M165.788 7.50538C167.465 6.79357 168.551 5.57001 168.551 4.15599C168.551 1.90319 165.807 0.139038 162.305 0.139038H158.799V1.32989H162.305C165.043 1.32989 167.357 2.62463 167.357 4.15599C167.357 5.68544 165.043 6.97826 162.305 6.97826H158.799V8.16911H162.305C165.499 8.16911 168.198 9.55426 168.198 11.1934C168.198 12.8344 165.501 14.2215 162.305 14.2215H158.799V15.4123H162.305C166.28 15.4123 169.393 13.5578 169.393 11.1914C169.393 9.58697 167.955 8.21912 165.788 7.50538Z" fill="white"/>
            <path d="M202.146 1.4511H207.366V15.4258H208.609V1.4511H213.9V0.212158H202.146V1.4511Z" fill="white"/>
            <path d="M7.32483 7.09615C7.18975 7.07691 7.02959 7.05767 6.86749 7.04036C6.72084 7.02305 6.57032 7.00573 6.43524 6.98841C6.15544 6.94994 5.99528 6.92493 5.97019 6.91916C4.3782 6.55555 2.67814 5.65712 2.67814 4.2181C2.67814 2.69828 4.60976 1.41316 6.89644 1.41316C8.52702 1.41316 10.0303 2.05956 10.7288 3.05803L11.767 2.3366C10.8253 0.987992 8.95928 0.151123 6.89644 0.151123C3.87262 0.151123 1.41227 1.97492 1.41227 4.2181C1.41227 6.04189 3.05057 7.54825 5.68652 8.14848C5.76757 8.16772 6.00106 8.20235 6.24806 8.23506C6.2635 8.23698 6.27894 8.2389 6.29438 8.24083C6.34648 8.24852 6.40051 8.25429 6.45261 8.26199C6.46612 8.26391 6.47963 8.26776 6.49313 8.26968L6.63979 8.29085C6.86364 8.32163 7.12221 8.35625 7.40974 8.39473C7.42324 8.39666 7.43675 8.39666 7.45026 8.39666C9.47257 8.76796 10.8118 9.88185 10.8118 11.2266C10.8118 12.8195 8.74315 14.1662 6.29438 14.1662C4.1312 14.1662 2.21695 13.1177 1.84645 11.7383L1.83873 11.7076L1.22702 11.8692L0.621094 12.0539C1.1479 14.0085 3.53492 15.4263 6.29438 15.4263C9.48415 15.4263 12.0777 13.5429 12.0777 11.2247C12.0777 9.16042 10.1673 7.50015 7.32483 7.09615Z" fill="white"/>
            <path d="M227.77 7.09361C227.635 7.07438 227.477 7.05706 227.315 7.03782C227.176 7.02243 227.035 7.00704 226.906 6.98973C226.61 6.94932 226.439 6.92239 226.414 6.91662C224.818 6.55494 223.114 5.66036 223.114 4.22903C223.114 2.7169 225.051 1.43755 227.344 1.43755C228.978 1.43755 230.487 2.08012 231.186 3.07666L232.203 2.36484C231.263 1.0297 229.401 0.198608 227.342 0.198608C224.324 0.198608 221.869 2.00701 221.869 4.22903C221.869 6.03551 223.504 7.5284 226.14 8.12478C226.213 8.1421 226.429 8.1748 226.695 8.21136C226.697 8.21136 226.699 8.21136 226.701 8.21136C226.765 8.21905 226.828 8.22867 226.896 8.23829C226.911 8.24214 226.925 8.24406 226.94 8.24599L227.857 8.36911C227.871 8.37103 227.886 8.37103 227.899 8.37103C229.928 8.74041 231.269 9.84854 231.269 11.1875C231.269 12.7728 229.194 14.1117 226.74 14.1117C224.571 14.1117 222.653 13.069 222.274 11.6742L221.068 11.9744C221.07 11.9782 221.076 12.0032 221.078 12.0071C221.603 13.9444 223.986 15.3488 226.74 15.3488C229.976 15.3488 232.511 13.5192 232.511 11.1856C232.513 9.13864 230.607 7.49377 227.77 7.09361Z" fill="white"/>
            <path d="M95.9384 0.215942H85.5566V1.47411H95.9384V0.215942Z" fill="white"/>
            <path d="M95.9384 7.24756H85.5566V8.50574H95.9384V7.24756Z" fill="white"/>
            <path d="M95.9384 14.1176H85.5566V15.3757H95.9384V14.1176Z" fill="white"/>
            <path d="M52.9482 0.144775H42.5664C42.2133 0.144775 41.9258 0.429507 41.9258 0.781567V8.14404C41.9258 8.21715 41.9412 8.28448 41.9624 8.34989H41.9354V15.4276H43.1974V8.78276H52.3094V15.4276H53.5888V8.14597V0.783487C53.5869 0.429503 53.3013 0.144775 52.9482 0.144775ZM52.3075 7.50726H43.2052V1.41835H52.3075V7.50726Z" fill="white"/>
          </svg>
        </Navbar.Brand>
        <Navbar.Toggle className="navbar-dark justify-content-end" />
        <Navbar.Collapse className="justify-content-end">
          <Items />
          <div className="d-flex flex-row align-items-center justify-content-center ms-3 mt-3 mt-lg-0 mb-md-2">
            <SocialLink link="https://discord.gg/spacerobots" image={discord} title="Discord" />
            <SocialLink link="https://twitter.com/SpaceRobotsNFT" image={twitter} title="Twitter" />
            <SocialLink link="https://t.me/spacerobots" image={telegram} title="Telegram" />
          </div>
        </Navbar.Collapse> 
      </Container>
    </Navbar>
  );
};

export default AppNav;