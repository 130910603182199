const host = "https://profound-crostata-640e8d.netlify.app/Low-SRC";


interface LayerType {
    folder: string
    filename: string
    nonce?: string
}
const roboParts: Array<LayerType> = [
    {folder:"Background", filename:"Bad_Signal_150.png", nonce:"0"},
    {folder:"Background", filename:"Dark_Planet_150.png", nonce:"0"},
    {folder:"Background", filename:"Desolate_150.png", nonce:"0"},
    {folder:"Background", filename:"DigitalNet_Blue_150.png", nonce:"0"},
    {folder:"Background", filename:"DigitalNet_Green_150.png", nonce:"0"},
    {folder:"Background", filename:"DigitalNet_Noir_150.png", nonce:"0"},
    {folder:"Background", filename:"DigitalNet_Yellow_150.png", nonce:"0"},
    {folder:"Background", filename:"Elrond_250.png", nonce:"0"},
    {folder:"Background", filename:"Holo_Desert_Green_175.png", nonce:"0"},
    {folder:"Background", filename:"Holo_Scrap_Yard_175.png", nonce:"0"},
    {folder:"Background", filename:"Hypno_Orange_25.png", nonce:"0"},
    {folder:"Background", filename:"Hypno_Purple_25.png", nonce:"0"},
    {folder:"Background", filename:"Hypno_Square_25.png", nonce:"0"},
    {folder:"Background", filename:"Hypno_Toxic_25.png", nonce:"0"},
    {folder:"Background", filename:"Illusion_250.png", nonce:"0"},
    {folder:"Background", filename:"Mars_150.png", nonce:"0"},
    {folder:"Background", filename:"Matrix_Numbers_100.png", nonce:"0"},
    {folder:"Background", filename:"Metal_Blue_450.png", nonce:"0"},
    {folder:"Background", filename:"Metal_Blue_Deep_450.png", nonce:"0"},
    {folder:"Background", filename:"Metal_Electric_450.png", nonce:"0"},
    {folder:"Background", filename:"Metal_Green_450.png", nonce:"0"},
    {folder:"Background", filename:"Metal_Green_Bright_350.png", nonce:"0"},
    {folder:"Background", filename:"Metal_Green_Military_450.png", nonce:"0"},
    {folder:"Background", filename:"Metal_Grey_450.png", nonce:"0"},
    {folder:"Background", filename:"Metal_Purple_450.png", nonce:"0"},
    {folder:"Background", filename:"Metal_Purple_Deep_450.png", nonce:"0"},
    {folder:"Background", filename:"Metal_Red_450.png", nonce:"0"},
    {folder:"Background", filename:"Metal_Red_Bright_350.png", nonce:"0"},
    {folder:"Background", filename:"Metal_Rusty_450.png", nonce:"0"},
    {folder:"Background", filename:"Metal_Yellow_450.png", nonce:"0"},
    {folder:"Background", filename:"Moon_150.png", nonce:"0"},
    {folder:"Background", filename:"Radioactive_250.png", nonce:"0"},
    {folder:"Background", filename:"Rainbow_50.png", nonce:"0"},
    {folder:"Background", filename:"Rainbow_Noir_50.png", nonce:"0"},
    {folder:"Background", filename:"RainxBow_100.png", nonce:"0"},
    {folder:"Background", filename:"RainxBow_Noir_100.png", nonce:"0"},
    {folder:"Background", filename:"RoboCell_Colorful_250.png", nonce:"0"},
    {folder:"Background", filename:"Robopack_Hook_100.png", nonce:"0"},
    {folder:"Background", filename:"RoboPackWall_175.png", nonce:"0"},
    {folder:"Background", filename:"Skull_250.png", nonce:"0"},
    {folder:"Background", filename:"Space_450.png", nonce:"0"},
    {folder:"Background", filename:"Tunnel_Vision_175.png", nonce:"0"},
    {folder:"Body", filename:"AuGolBot_A22_D22_275.png", nonce:"0"},
    {folder:"Body", filename:"BulkyBot_A6_D6_625.png", nonce:"0"},
    {folder:"Body", filename:"ChipBot_A25_D27_150.png", nonce:"0"},
    {folder:"Body", filename:"ChromeBot_A26_D25_160.png", nonce:"0"},
    {folder:"Body", filename:"CraneBot_A10_D12_450.png", nonce:"0"},
    {folder:"Body", filename:"ElrondBot_A16_D15_350.png", nonce:"0"},
    {folder:"Body", filename:"FireBot_A10_D11_475.png", nonce:"0"},
    {folder:"Body", filename:"GolBot_A4_D2_500.png", nonce:"0"},
    {folder:"Body", filename:"HeavyBot_A18_D20_350.png", nonce:"0"},
    {folder:"Body", filename:"HornetBot_A15_D14_380.png", nonce:"0"},
    {folder:"Body", filename:"IceCreamBot_A12_D15_425.png", nonce:"0"},
    {folder:"Body", filename:"JollyBot_Black_A20_D19_300.png", nonce:"0"},
    {folder:"Body", filename:"JollyBot_Red_A13_D15_400.png", nonce:"0"},
    {folder:"Body", filename:"MaiarBot_A21_D22_280.png", nonce:"0"},
    {folder:"Body", filename:"NoirBot_A9_D10_535.png", nonce:"0"},
    {folder:"Body", filename:"PoliceBot_A9_D11_500.png", nonce:"0"},
    {folder:"Body", filename:"RockBot_A24_D23_220.png", nonce:"0"},
    {folder:"Body", filename:"RustBot_A4_D6_675.png", nonce:"0"},
    {folder:"Body", filename:"ScienceBot_A26_D26_175.png", nonce:"0"},
    {folder:"Body", filename:"SpaceRide_A24_D24_200.png", nonce:"0"},
    {folder:"Body", filename:"StreetBot_A17_D18_320.png", nonce:"0"},
    {folder:"Body", filename:"StrikerBot_A17_D18_375.png", nonce:"0"},
    {folder:"Body", filename:"TestBot_A11_D11_480.png", nonce:"0"},
    {folder:"Body", filename:"ToxicBot_A22_D24_225.png", nonce:"0"},
    {folder:"Body", filename:"TrafficBot_A7_D8_575.png", nonce:"0"},
    {folder:"Body", filename:"WarriorBot_A7_D6_600.png", nonce:"0"},
    {folder:"Ears", filename:"Woolly-Horn_A0_D6_500.png", nonce:"SRP-ec2514-a2"},
    {folder:"Ears", filename:"Asterix_A8_D7_320.png", nonce:"SRP-ec2514-0d"},
    {folder:"Ears", filename:"Attachment_A1_D2_600.png", nonce:"SRP-ec2514-1b"},
    {folder:"Ears", filename:"Blue_Alert_A7_D8_300.png", nonce:"SRP-ec2514-09"},
    {folder:"Ears", filename:"Change_A2_D5_430.png", nonce:"SRP-ec2514-12"},
    {folder:"Ears", filename:"Chappy_A13_D11_100.png", nonce:"SRP-ec2514-02"},
    {folder:"Ears", filename:"CocuBones_A7_D7_100.png", nonce:"SRP-ec2514-9c"},
    {folder:"Ears", filename:"Drumsticks_A8_D6_260.png", nonce:"SRP-ec2514-0a"},
    {folder:"Ears", filename:"Elrond_Bolt_A9_D9_210.png", nonce:"SRP-ec2514-06"},
    {folder:"Ears", filename:"ER_Wwwine_A11_D10_100.png", nonce:"SRP-ec2514-94"},
    {folder:"Ears", filename:"Exhaust_A9_D6_240.png", nonce:"SRP-ec2514-08"},
    {folder:"Ears", filename:"Flower_Pot_A9_D11_170.png", nonce:"SRP-ec2514-04"},
    {folder:"Ears", filename:"GolBot_A3_D1_500.png", nonce:"SRP-ec2514-1a"},
    {folder:"Ears", filename:"Gold_Bulb_A2_D2_470.png", nonce:"SRP-ec2514-16"},
    {folder:"Ears", filename:"Golden_A6_D8_220.png", nonce:"SRP-ec2514-07"},
    {folder:"Ears", filename:"Grating_A2_D3_510.png", nonce:"SRP-ec2514-18"},
    {folder:"Ears", filename:"Green_Bulb_A2_D2_470.png", nonce:"SRP-ec2514-15"},
    {folder:"Ears", filename:"Horny_A7_D6_420.png", nonce:"SRP-ec2514-0f"},
    {folder:"Ears", filename:"Lightbulb_A4_D2_480.png", nonce:"SRP-ec2514-19"},
    {folder:"Ears", filename:"Missing_A0_D0_500.png", nonce:"N/A"},
    {folder:"Ears", filename:"Old_Speaker_A5_D6_360.png", nonce:"SRP-ec2514-0e"},
    {folder:"Ears", filename:"Phone_A3_D2_680.png", nonce:"SRP-ec2514-17"},
    {folder:"Ears", filename:"Radio_Set_A6_D7_360.png", nonce:"SRP-ec2514-0c"},
    {folder:"Ears", filename:"Red_Alert_A3_D5_500.png", nonce:"SRP-ec2514-11"},
    {folder:"Ears", filename:"Red_Bulb_A2_D3_430.png", nonce:"SRP-ec2514-14"},
    {folder:"Ears", filename:"Satellite_A3_D4_480.png", nonce:"SRP-ec2514-13"},
    {folder:"Ears", filename:"Screws_A5_D6_400.png", nonce:"SRP-ec2514-10"},
    {folder:"Ears", filename:"TapeReel_A10_D10_190.png", nonce:"SRP-ec2514-05"},
    {folder:"Ears", filename:"Wheels_A8_D8_280.png", nonce:"SRP-ec2514-0b"},
    {folder:"Ears", filename:"Wings_A11_D9_120.png", nonce:"SRP-ec2514-03"},
    {folder:"Ears", filename:"X-Phones_A10_A10_600.png", nonce:"SRP-ec2514-a8"},
    {folder:"Ears", filename:"PurplePulse_A3_D2_100.png", nonce:"SRP-ec2514-af"},
    {folder:"Eyes", filename:"Glowy-Cones_A0_D2_500.png", nonce:"SRP-ec2514-a3"},
    {folder:"Eyes", filename:"32D2_A6_D5_340.png", nonce:"SRP-ec2514-2b"},
    {folder:"Eyes", filename:"3D2D_Pink_A4_D2_380.png", nonce:"SRP-ec2514-34"},
    {folder:"Eyes", filename:"Assasin_Dark_A12_D10_80.png", nonce:"SRP-ec2514-20"},
    {folder:"Eyes", filename:"Assasin_Red_A11_D10_100.png", nonce:"SRP-ec2514-21"},
    {folder:"Eyes", filename:"Attachment_A1_D2_500.png", nonce:"SRP-ec2514-3a"},
    {folder:"Eyes", filename:"AuspEyes_A7_D8_100.png", nonce:"SRP-ec2514-9a"},
    {folder:"Eyes", filename:"Camera_Lens_A6_D4_370.png", nonce:"SRP-ec2514-2c"},
    {folder:"Eyes", filename:"Contacts_A5_D4_420.png", nonce:"SRP-ec2514-2f"},
    {folder:"Eyes", filename:"Cyclop_Gold_A3_D3_500.png", nonce:"SRP-ec2514-33"},
    {folder:"Eyes", filename:"Cyclop_Green_A3_D2_600.png", nonce:"SRP-ec2514-38"},
    {folder:"Eyes", filename:"Cyclop_Red_A3_D3_500.png", nonce:"SRP-ec2514-32"},
    {folder:"Eyes", filename:"Cyclopz_A12_D9_120.png", nonce:"SRP-ec2514-1c"},
    {folder:"Eyes", filename:"E_Motion_Angry_A11_D8_180.png", nonce:"SRP-ec2514-27"},
    {folder:"Eyes", filename:"E_Motion_Dead_A8_D11_180.png", nonce:"SRP-ec2514-25"},
    {folder:"Eyes", filename:"E_Motion_Happy_A7_D10_200.png", nonce:"SRP-ec2514-26"},
    {folder:"Eyes", filename:"E_Motion_Round_A10_D7_200.png", nonce:"SRP-ec2514-28"},
    {folder:"Eyes", filename:"ES_Wwwine_A11_D11_100.png", nonce:"SRP-ec2514-95"},
    {folder:"Eyes", filename:"Fury_A11_D9_140.png", nonce:"SRP-ec2514-22"},
    {folder:"Eyes", filename:"Ghost_A9_D10_100.png", nonce:"SRP-ec2514-99"},
    {folder:"Eyes", filename:"Gogles_A10_D10_110.png", nonce:"SRP-ec2514-1f"},
    {folder:"Eyes", filename:"GolBot_A3_D1_500.png", nonce:"SRP-ec2514-39"},
    {folder:"Eyes", filename:"GolBot_Red_A4_D1_400.png", nonce:"SRP-ec2514-35"},
    {folder:"Eyes", filename:"Grated_Eyes_A7_D7_320.png", nonce:"SRP-ec2514-29"},
    {folder:"Eyes", filename:"Hexa_A2_D4_500.png", nonce:"SRP-ec2514-37"},
    {folder:"Eyes", filename:"JollyBot_A9_D9_130.png", nonce:"SRP-ec2514-1e"},
    {folder:"Eyes", filename:"Laser_Beam_A13_D10_65.png", nonce:"SRP-ec2514-1d"},
    {folder:"Eyes", filename:"Maiar_Liquid_A8_D7_260.png", nonce:"SRP-ec2514-2d"},
    {folder:"Eyes", filename:"Missing_A0_D0_500.png", nonce:"N/A"},
    {folder:"Eyes", filename:"No_TV_Signal_A4_D7_350.png", nonce:"SRP-ec2514-2e"},
    {folder:"Eyes", filename:"Orange_Bulbs_A7_D6_300.png", nonce:"SRP-ec2514-2a"},
    {folder:"Eyes", filename:"Popped_Out_A2_D4_420.png", nonce:"SRP-ec2514-31"},
    {folder:"Eyes", filename:"Purple_A4_D3_475.png", nonce:"SRP-ec2514-36"},
    {folder:"Eyes", filename:"Sewerage_A5_D2_400.png", nonce:"SRP-ec2514-30"},
    {folder:"Eyes", filename:"Tony_Glasses_A7_D7_240.png", nonce:"SRP-ec2514-23"},
    {folder:"Eyes", filename:"VR_Headset_A8_D7_220.png", nonce:"SRP-ec2514-24"},
    {folder:"Eyes", filename:"X-Vision_A9_D9_600.png", nonce:"SRP-ec2514-a9"},
    {folder:"Eyes", filename:"SR-Train_A3_D4_200.png", nonce:"SRP-ec2514-ad"},
    {folder:"Hand_Left", filename:"HNL_Gift-Canons_A0_D10_500.png", nonce:"SRP-ec2514-a5"},
    {folder:"Hand_Left", filename:"HNL_ApreliaBot_A12_D13_230.png", nonce:"SRP-ec2514-7e"},
    {folder:"Hand_Left", filename:"HNL_AuGolBot_A15_D15_230.png", nonce:"SRP-ec2514-82"},
    {folder:"Hand_Left", filename:"HNL_BulkyBot_A4_D4_525.png", nonce:"SRP-ec2514-91"},
    {folder:"Hand_Left", filename:"HNL_ChipBot_A17_D18_130.png", nonce:"SRP-ec2514-77"},
    {folder:"Hand_Left", filename:"HNL_ChromeBot_A17_D17_140.png", nonce:"SRP-ec2514-7b"},
    {folder:"Hand_Left", filename:"HNL_CraneBot_A7_D8_405.png", nonce:"SRP-ec2514-89"},
    {folder:"Hand_Left", filename:"HNL_ElrondBot_A11_D10_305.png", nonce:"SRP-ec2514-85"},
    {folder:"Hand_Left", filename:"HNL_FireBot_A7_D7_430.png", nonce:"SRP-ec2514-8a"},
    {folder:"Hand_Left", filename:"HNL_GolBot_A3_D1_500.png", nonce:"SRP-ec2514-92"},
    {folder:"Hand_Left", filename:"HNL_HeavyBot_A12_D13_305.png", nonce:"SRP-ec2514-83"},
    {folder:"Hand_Left", filename:"HNL_HornetBot_A10_D9_340.png", nonce:"SRP-ec2514-86"},
    {folder:"Hand_Left", filename:"HNL_IceCreamBot_A8_D10_380.png", nonce:"SRP-ec2514-88"},
    {folder:"Hand_Left", filename:"HNL_JennyBot_A14_D15_210.png", nonce:"SRP-ec2514-7c"},
    {folder:"Hand_Left", filename:"HNL_JollyBot_Black_A13_D13_255.png", nonce:"SRP-ec2514-81"},
    {folder:"Hand_Left", filename:"HNL_JollyBot_Red_A9_D10_355.png", nonce:"SRP-ec2514-84"},
    {folder:"Hand_Left", filename:"HNL_KabukiBot_A13_D14_220.png", nonce:"SRP-ec2514-7d"},
    {folder:"Hand_Left", filename:"HNL_MaiarBot_A14_D15_235.png", nonce:"SRP-ec2514-80"},
    {folder:"Hand_Left", filename:"HNL_Missing_A0_D0_500.png", nonce:"N/A"},
    {folder:"Hand_Left", filename:"HNL_NoirBot_A6_D7_460.png", nonce:"SRP-ec2514-8d"},
    {folder:"Hand_Left", filename:"HNL_PoliceBot_A6_D7_455.png", nonce:"SRP-ec2514-8b"},
    {folder:"Hand_Left", filename:"HNL_RockBot_A16_D15_200.png", nonce:"SRP-ec2514-78"},
    {folder:"Hand_Left", filename:"HNL_RustBot_A3_D4_575.png", nonce:"SRP-ec2514-8f"},
    {folder:"Hand_Left", filename:"HNL_ScienceBot_A17_D17_155.png", nonce:"SRP-ec2514-79"},
    {folder:"Hand_Left", filename:"HNL_SpaceRide_A16_D16_180.png", nonce:"SRP-ec2514-76"},
    {folder:"Hand_Left", filename:"HNL_StreetBot_A11_D12_280.png", nonce:"SRP-ec2514-87"},
    {folder:"Hand_Left", filename:"HNL_StrikerBot_A11_D12_330.png", nonce:"SRP-ec2514-7f"},
    {folder:"Hand_Left", filename:"HNL_TestBot_A7_D7_435.png", nonce:"SRP-ec2514-8c"},
    {folder:"Hand_Left", filename:"HNL_Totems_A11_D11_100.png", nonce:"SRP-ec2514-9e"},
    {folder:"Hand_Left", filename:"HNL_ToxicBot_A15_D16_205.png", nonce:"SRP-ec2514-7a"},
    {folder:"Hand_Left", filename:"HNL_TrafficBot_A5_D5_480.png", nonce:"SRP-ec2514-8e"},
    {folder:"Hand_Left", filename:"HNL_WarriorBot_A5_D4_550.png", nonce:"SRP-ec2514-90"},
    {folder:"Hand_Left", filename:"HNL_Wwwine_A16_D17_100.png", nonce:"SRP-ec2514-98"},
    {folder:"Hand_Left", filename:"HNL_X-Tactile_A13_D13_600.png", nonce:"SRP-ec2514-a6"},
    {folder:"Hand_Left", filename:"HNL_RetroGrip_A4_D5_100.png", nonce:"SRP-ec2514-b0"},
    {folder:"Hand_Right", filename:"HN_Gift-Canons_A0_D10_500.png", nonce:"SRP-ec2514-a5"},
    {folder:"Hand_Right", filename:"HN_ApreliaBot_A12_D13_250.png", nonce:"SRP-ec2514-7e"},
    {folder:"Hand_Right", filename:"HN_AuGolBot_A15_D15_250.png", nonce:"SRP-ec2514-82"},
    {folder:"Hand_Right", filename:"HN_BulkyBot_A4_D4_525.png", nonce:"SRP-ec2514-91"},
    {folder:"Hand_Right", filename:"HN_ChipBot_A17_D18_150.png", nonce:"SRP-ec2514-77"},
    {folder:"Hand_Right", filename:"HN_ChromeBot_A17_D17_160.png", nonce:"SRP-ec2514-7b"},
    {folder:"Hand_Right", filename:"HN_CraneBot_A7_D8_425.png", nonce:"SRP-ec2514-89"},
    {folder:"Hand_Right", filename:"HN_ElrondBot_A11_D10_325.png", nonce:"SRP-ec2514-85"},
    {folder:"Hand_Right", filename:"HN_FireBot_A7_D7_450.png", nonce:"SRP-ec2514-8a"},
    {folder:"Hand_Right", filename:"HN_GolBot_A3_D1_500.png", nonce:"SRP-ec2514-92"},
    {folder:"Hand_Right", filename:"HN_HeavyBot_A12_D13_325.png", nonce:"SRP-ec2514-83"},
    {folder:"Hand_Right", filename:"HN_HornetBot_A10_D9_360.png", nonce:"SRP-ec2514-86"},
    {folder:"Hand_Right", filename:"HN_IceCreamBot_A8_D10_400.png", nonce:"SRP-ec2514-88"},
    {folder:"Hand_Right", filename:"HN_JennyBot_A14_D15_230.png", nonce:"SRP-ec2514-7c"},
    {folder:"Hand_Right", filename:"HN_JollyBot_Black_A13_D13_275.png", nonce:"SRP-ec2514-81"},
    {folder:"Hand_Right", filename:"HN_JollyBot_Red_A9_D10_375.png", nonce:"SRP-ec2514-84"},
    {folder:"Hand_Right", filename:"HN_KabukiBot_A13_D14_240.png", nonce:"SRP-ec2514-7d"},
    {folder:"Hand_Right", filename:"HN_MaiarBot_A14_D15_255.png", nonce:"SRP-ec2514-80"},
    {folder:"Hand_Right", filename:"HN_Missing_A0_D0_500.png", nonce:"N/A"},
    {folder:"Hand_Right", filename:"HN_NoirBot_A6_D7_480.png", nonce:"SRP-ec2514-8d"},
    {folder:"Hand_Right", filename:"HN_PoliceBot_A6_D7_475.png", nonce:"SRP-ec2514-8b"},
    {folder:"Hand_Right", filename:"HN_RockBot_A16_D15_220.png", nonce:"SRP-ec2514-78"},
    {folder:"Hand_Right", filename:"HN_RustBot_A3_D4_575.png", nonce:"SRP-ec2514-8f"},
    {folder:"Hand_Right", filename:"HN_ScienceBot_A17_D17_175.png", nonce:"SRP-ec2514-79"},
    {folder:"Hand_Right", filename:"HN_SpaceRide_A16_D16_200.png", nonce:"SRP-ec2514-76"},
    {folder:"Hand_Right", filename:"HN_StreetBot_A11_D12_300.png", nonce:"SRP-ec2514-87"},
    {folder:"Hand_Right", filename:"HN_StrikerBot_A11_D12_350.png", nonce:"SRP-ec2514-7f"},
    {folder:"Hand_Right", filename:"HN_TestBot_A7_D7_455.png", nonce:"SRP-ec2514-8c"},
    {folder:"Hand_Right", filename:"HN_Totems_A11_D11_100.png", nonce:"SRP-ec2514-9e"},
    {folder:"Hand_Right", filename:"HN_ToxicBot_A15_D16_225.png", nonce:"SRP-ec2514-7a"},
    {folder:"Hand_Right", filename:"HN_TrafficBot_A5_D5_500.png", nonce:"SRP-ec2514-8e"},
    {folder:"Hand_Right", filename:"HN_WarriorBot_A5_D4_550.png", nonce:"SRP-ec2514-90"},
    {folder:"Hand_Right", filename:"HN_Wwwine_A16_D17_100.png", nonce:"SRP-ec2514-98"},
    {folder:"Hand_Right", filename:"HN_X-Tactile_A13_D13_600.png", nonce:"SRP-ec2514-a6"},
    {folder:"Hand_Right", filename:"HN_RetroGrip_A4_D5_100.png", nonce:"SRP-ec2514-b0"},
    {folder:"Head", filename:"AuGolBot_A17_D17_275.png", nonce:"0"},
    {folder:"Head", filename:"BulkyBot_A5_D5_625.png", nonce:"0"},
    {folder:"Head", filename:"ChipBot_A19_D21_150.png", nonce:"0"},
    {folder:"Head", filename:"ChromeBot_A20_D19_160.png", nonce:"0"},
    {folder:"Head", filename:"CraneBot_A8_D9_450.png", nonce:"0"},
    {folder:"Head", filename:"ElrondBot_A12_D12_350.png", nonce:"0"},
    {folder:"Head", filename:"FireBot_A8_D8_475.png", nonce:"0"},
    {folder:"Head", filename:"GolBot_A3_D2_500.png", nonce:"0"},
    {folder:"Head", filename:"HeavyBot_A14_D15_350.png", nonce:"0"},
    {folder:"Head", filename:"HornetBot_A12_D11_380.png", nonce:"0"},
    {folder:"Head", filename:"IceCreamBot_A9_D12_425.png", nonce:"0"},
    {folder:"Head", filename:"JollyBot_Red_A10_D12_40.png", nonce:"0"},
    {folder:"Head", filename:"JollyBotBlack_A15_D15_300.png", nonce:"0"},
    {folder:"Head", filename:"MaiarBot_A16_D17_280.png", nonce:"0"},
    {folder:"Head", filename:"NoirBot_A7_D8_535.png", nonce:"0"},
    {folder:"Head", filename:"PoliceBot_A7_D8_500.png", nonce:"0"},
    {folder:"Head", filename:"RockBot_A18_D18_220.png", nonce:"0"},
    {folder:"Head", filename:"RustBot_A3_D5_675.png", nonce:"0"},
    {folder:"Head", filename:"ScienceBot_A20_D20_175.png", nonce:"0"},
    {folder:"Head", filename:"SpaceRide_A18_D18_200.png", nonce:"0"},
    {folder:"Head", filename:"StreetBot_A13_D14_320.png", nonce:"0"},
    {folder:"Head", filename:"StrikerBot_A13_D14_375.png", nonce:"0"},
    {folder:"Head", filename:"TestBot_A8_D8_480.png", nonce:"0"},
    {folder:"Head", filename:"ToxicBot_A17_D18_225.png", nonce:"0"},
    {folder:"Head", filename:"TrafficBot_A5_D6_575.png", nonce:"0"},
    {folder:"Head", filename:"WarriorBot_A5_D5_600.png", nonce:"0"},
    {folder:"Head_Slot", filename:"G-JollyHat_A0_D8_500.png", nonce:"SRP-ec2514-a0"},
    {folder:"Head_Slot", filename:"O-JollyHat_A0_D8_500.png", nonce:"SRP-ec2514-a1"},
    {folder:"Head_Slot", filename:"HS-JollyHat_A0_D7_500.png", nonce:"SRP-ec2514-9f"},
    {folder:"Head_Slot", filename:"WarBonnet_A5_D6_100.png", nonce:"SRP-ec2514-9b"},
    {folder:"Head_Slot", filename:"Antenna_A2_D2_500.png", nonce:"SRP-ec2514-6c"},
    {folder:"Head_Slot", filename:"Attachment_A1_D2_500.png", nonce:"SRP-ec2514-75"},
    {folder:"Head_Slot", filename:"Beanie_A6_D5_250.png", nonce:"SRP-ec2514-61"},
    {folder:"Head_Slot", filename:"Black_Cap_A9_D8_150.png", nonce:"SRP-ec2514-59"},
    {folder:"Head_Slot", filename:"Blue_Cap_A6_D7_210.png", nonce:"SRP-ec2514-5f"},
    {folder:"Head_Slot", filename:"Broken_A0_D1_600.png", nonce:"SRP-ec2514-71"},
    {folder:"Head_Slot", filename:"Bucket_A5_D4_350.png", nonce:"SRP-ec2514-64"},
    {folder:"Head_Slot", filename:"Camera_A8_D9_120.png", nonce:"SRP-ec2514-5c"},
    {folder:"Head_Slot", filename:"ChainMohawk_A5_D4_285.png", nonce:"SRP-ec2514-66"},
    {folder:"Head_Slot", filename:"Citizen_Mohawk_A2_D1_700.png", nonce:"SRP-ec2514-72"},
    {folder:"Head_Slot", filename:"Emergency_A3_D2_400.png", nonce:"SRP-ec2514-6f"},
    {folder:"Head_Slot", filename:"Evargreen_A4_D4_300.png", nonce:"SRP-ec2514-69"},
    {folder:"Head_Slot", filename:"Extinguisher_A2_D3_350.png", nonce:"SRP-ec2514-6d"},
    {folder:"Head_Slot", filename:"Fire_Hydrant_A7_D6_200.png", nonce:"SRP-ec2514-62"},
    {folder:"Head_Slot", filename:"Holographic_A3_D3_500.png", nonce:"SRP-ec2514-6e"},
    {folder:"Head_Slot", filename:"HS_Wwwine_A10_D9_100.png", nonce:"SRP-ec2514-97"},
    {folder:"Head_Slot", filename:"Jolly_Top_Hat_A4_D5_285.png", nonce:"SRP-ec2514-6a"},
    {folder:"Head_Slot", filename:"Lightbulb_A1_D1_500.png", nonce:"SRP-ec2514-73"},
    {folder:"Head_Slot", filename:"Miner_A5_D5_300.png", nonce:"SRP-ec2514-68"},
    {folder:"Head_Slot", filename:"Missing_A0_D0_500.png", nonce:"N/A"},
    {folder:"Head_Slot", filename:"Mohawk_A4_D4_300.png", nonce:"SRP-ec2514-67"},
    {folder:"Head_Slot", filename:"Nuke_A10_D5_180.png", nonce:"SRP-ec2514-63"},
    {folder:"Head_Slot", filename:"Police_A2_D3_425.png", nonce:"SRP-ec2514-70"},
    {folder:"Head_Slot", filename:"Propeller_A8_D10_100.png", nonce:"SRP-ec2514-5b"},
    {folder:"Head_Slot", filename:"Red_Cap_A8_D8_130.png", nonce:"SRP-ec2514-5a"},
    {folder:"Head_Slot", filename:"SatDish_A4_D5_315.png", nonce:"SRP-ec2514-65"},
    {folder:"Head_Slot", filename:"Slim_Mohawk_A2_D3_550.png", nonce:"SRP-ec2514-6b"},
    {folder:"Head_Slot", filename:"SpaceRide_A11_D9_65.png", nonce:"SRP-ec2514-5d"},
    {folder:"Head_Slot", filename:"Spike_Mohawk_A9_D9_200.png", nonce:"SRP-ec2514-58"},
    {folder:"Head_Slot", filename:"Spoiler_Mohawk_A1_D2_600.png", nonce:"SRP-ec2514-74"},
    {folder:"Head_Slot", filename:"Tesla_Coils_A12_D9_80.png", nonce:"SRP-ec2514-5e"},
    {folder:"Head_Slot", filename:"Top_Hat_Brown_A7_D6_220.png", nonce:"SRP-ec2514-60"},
    {folder:"Head_Slot", filename:"X-Wave_A8_D8_600.png", nonce:"SRP-ec2514-aa"},
    {folder:"Head_Slot", filename:"Spacerrow_A2_D2_100.png", nonce:"SRP-ec2514-ae"},
    {folder:"Mouth", filename:"Santa-Stash_A0_D4_500.png", nonce:"SRP-ec2514-a4"},
    {folder:"Mouth", filename:"Attachment_A1_D2_650.png", nonce:"SRP-ec2514-57"},
    {folder:"Mouth", filename:"Bumper_Blue_A2_D1_525.png", nonce:"SRP-ec2514-56"},
    {folder:"Mouth", filename:"Bumper_Rusty_A1_D1_525.png", nonce:"SRP-ec2514-55"},
    {folder:"Mouth", filename:"Capsule_A10_D8_120.png", nonce:"SRP-ec2514-40"},
    {folder:"Mouth", filename:"Car_Filter_A3_D5_375.png", nonce:"SRP-ec2514-4c"},
    {folder:"Mouth", filename:"Crypto_A6_D7_240.png", nonce:"SRP-ec2514-43"},
    {folder:"Mouth", filename:"Gas_Mask_A9_D10_150.png", nonce:"SRP-ec2514-93"},
    {folder:"Mouth", filename:"GolBot_A3_D1_500.png", nonce:"SRP-ec2514-54"},
    {folder:"Mouth", filename:"GolBot_Red_A4_D1_400.png", nonce:"SRP-ec2514-51"},
    {folder:"Mouth", filename:"Harmonica_A8_D9_160.png", nonce:"SRP-ec2514-3e"},
    {folder:"Mouth", filename:"Heart_Monitor_A5_D5_300.png", nonce:"SRP-ec2514-49"},
    {folder:"Mouth", filename:"Knight_Shield_A2_D3_600.png", nonce:"SRP-ec2514-53"},
    {folder:"Mouth", filename:"Laser_Net_A6_D8_210.png", nonce:"SRP-ec2514-45"},
    {folder:"Mouth", filename:"Leaky_A4_D3_325.png", nonce:"SRP-ec2514-4b"},
    {folder:"Mouth", filename:"Microphone_A2_D3_400.png", nonce:"SRP-ec2514-4e"},
    {folder:"Mouth", filename:"Missing_A0_D0_500.png", nonce:"N/A"},
    {folder:"Mouth", filename:"MT_Wwwine_A10_D9_100.png", nonce:"SRP-ec2514-96"},
    {folder:"Mouth", filename:"Mustache_A6_D6_300.png", nonce:"SRP-ec2514-47"},
    {folder:"Mouth", filename:"Nuttcracker_A6_D7_240.png", nonce:"SRP-ec2514-46"},
    {folder:"Mouth", filename:"Pipe_of_Destiny_A8_D4_100.png", nonce:"SRP-ec2514-9d"},
    {folder:"Mouth", filename:"Rainbow_A10_D8_80.png", nonce:"SRP-ec2514-3b"},
    {folder:"Mouth", filename:"Rainbow_Light_A11_D8_70.png", nonce:"SRP-ec2514-3d"},
    {folder:"Mouth", filename:"Retro_Radio_A6_D6_260.png", nonce:"SRP-ec2514-44"},
    {folder:"Mouth", filename:"Round_Shield_A2_D4_550.png", nonce:"SRP-ec2514-52"},
    {folder:"Mouth", filename:"Shocked_A3_D2_375.png", nonce:"SRP-ec2514-50"},
    {folder:"Mouth", filename:"Smile_A4_D3_550.png", nonce:"SRP-ec2514-4d"},
    {folder:"Mouth", filename:"Smoking_A9_D7_180.png", nonce:"SRP-ec2514-3f"},
    {folder:"Mouth", filename:"Speaker_A2_D2_400.png", nonce:"SRP-ec2514-4f"},
    {folder:"Mouth", filename:"Thermometer_A5_D6_240.png", nonce:"SRP-ec2514-42"},
    {folder:"Mouth", filename:"Trap_A5_D6_275.png", nonce:"SRP-ec2514-48"},
    {folder:"Mouth", filename:"Vent_A7_D6_200.png", nonce:"SRP-ec2514-41"},
    {folder:"Mouth", filename:"VU_Meter_A5_D5_300.png", nonce:"SRP-ec2514-4a"},
    {folder:"Mouth", filename:"X-Breath_A8_D8_600.png", nonce:"SRP-ec2514-a7"},
    {folder:"Mouth", filename:"Purmonix_A3_D4_100.png", nonce:"SRP-ec2514-ab"},
];

const order:{[key: string]: number} = {
    "Background": 100,
    "Hand_Left": 110,
    "Body": 120,
    "Hand_Right": 130,
    "Head": 140,
    "Head_Slot": 150,
    "Ears": 160,
    "Eyes": 170,
    "Mouth": 180
};

function findByNonce(nonce: string): Array<LayerType> {
    return roboParts.filter(item => item.nonce === nonce);
}

function findByFolderAndFilename(rawFolder: string, rawFilename: string): Array<LayerType> {
    const folder = rawFolder.replaceAll(" ", "_");
    const filename = rawFilename.replaceAll(" ", "_");
    const searchPattern = new RegExp(filename+'_[0-9][0-9][0-9]?.png');
    return roboParts.filter(item => (item.folder === folder) && (searchPattern.test(item.filename)));
}

/**
 * The attribute for hands in the NFT of space robots is "Hands" and the folder/path of layers is "Hand_Right" and "Hand_Left"
 * The value of the attribute "Hands" is "HN StrikerBot A11 D12" and the filename of the layer is "HN_StrikerBot_A11_D12" and "HNL_StrikerBot_A11_D12"
 */
function findHands(rawName: string): Array<LayerType> {
    const isLeftHand = rawName.startsWith("HNL");

    const hand_right = isLeftHand? rawName.replace("HNL", "HN"): rawName;
    const hand_left = isLeftHand? rawName: rawName.replace("HN", "HNL");

    const hand_right_layers = findByFolderAndFilename("Hand_Right", hand_right);
    const hand_left_layers = findByFolderAndFilename("Hand_Left", hand_left);

    return hand_right_layers.concat(hand_left_layers);
}

export {
    host,
    roboParts,
    order,
    findByNonce,
    findByFolderAndFilename,
    findHands
};