import { NFTType } from "cli/ElrondAPI";

export interface MessageType {
    show: boolean;
    title?: string;
    text?: string;
    danger?: boolean;
    onConfirm?: any;
    onCancel?: any;
    onHide?: any;

}
export interface StateType {
  showLogin: boolean;
  message: MessageType;
  spaceRobot?: NFTType;
}

const initialState = (): StateType => {
    return {
      showLogin: false,
      message: {show: false}
    };
};

export default initialState;