import React, { useEffect, useMemo, useState } from 'react';
import './styles.scss';
import { ReactComponent as AttackIcon} from "assets/icon/attack.svg"; 
import { ReactComponent as DefenseIcon} from "assets/icon/defense.svg"; 
import { NFTType, getCollection } from 'cli/ElrondAPI';
import { createTemplate } from 'cli/TemplateAPI';
import { useNavigate } from "react-router-dom";
import Button from 'components/Button';
import Desktop from 'components/Desktop';
import Mobile from 'components/Mobile';
import Pager from 'components/Pager';
import RobotComposition from 'components/RobotComposition';
import { useDispatch } from "context";
import Switch from 'components/Switch';
import Tooltip from 'components/Tooltip';
import Traits from 'components/Traits';
import Video from "components/Video";
import { ContractSpaceRobots } from "domain/ContractSpaceRobots";
import { collectionSpaceRobots, collectionRoboParts, tags, collectionGeneral, contractAddressJolly, jolly_pack_360 } from 'config';
import { useDispatch as useGlobalDispatch, useContext as useGlobalContext } from "context";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import { network } from "config";
import { routeNames } from 'routes';
import {useTrackTransactionStatus} from "@multiversx/sdk-dapp/hooks";
import { getExternalImageOfNFT, getAttributeFromNFT, parseAttributes, ROBOT_ATTRIBUTE_ATTACK, ROBOT_ATTRIBUTE_DEFENSE, ROBOPART_ATTRIBUTE_ATTACK, ROBOPART_ATTRIBUTE_DEFENSE, ROBOPART_ATTRIBUTE_KIND, getAttackDefense } from 'utils';
import { useContext } from '../Context';

const SpaceRobot = ({robot}: {robot: NFTType}) => {
    return (
        <div className="robot-slide">
            <div className='image'>
                <RobotComposition
                    spaceRobot = {robot}
                />
            </div>
        </div>
    );
};

const SpaceRobotNotFound = () => {
    return (
        <div className='robot-slide'>
            <div className='image'>
                <div className="robot-composition" style={{position: 'relative', display: 'inline-block'}}>
                    <div className="image not-found">
                        <img className="overlayed" src="/assets/robopart-not-found.jpg" style={{zIndex: 10}}/>
                        <div className='button-buy'>
                            <Button label="BUY A JOLLY BOT" href={"https://xoxno.com/collection/SPACEROBOT-bfbf9d?filters=%7B%22name%22%3A%22JollyBot%22%2C%22order%22%3A%22Price%3A+Low+to+High%22%2C%22orderValue%22%3A%22saleInfoNft%2Fmin_bid_short+asc%22%2C%22tokens%22%3A%5B%5D%2C%22attributes%22%3A%5B%5D%2C%22saleType%22%3A%22Nft%22%2C%22tab%22%3A%22Listed%22%7D"} target="_BLANK" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function predicateByCollection(collection: string) {
    return (nft: NFTType) => nft.collection === collectionGeneral.identifier && nft.name === 'JollyBot';
}

interface SwapPropsType {
    nfts: Array<NFTType>
}

const Swap = ({nfts}: SwapPropsType) => {
    const NFTsFlatByBalance = nfts?.flatMap(nft => Array(Number(nft.balance ?? 1)).fill(nft));
    const robots = useMemo(() => NFTsFlatByBalance.filter(predicateByCollection(collectionSpaceRobots.identifier)), [NFTsFlatByBalance]);
    const navigate = useNavigate();

    const dispatchGlobal = useGlobalDispatch();
    const { spaceRobot } = useGlobalContext();
    const { address } = useGetAccountInfo();;
    const dispatch = useDispatch();
    const [sessionID, setSessionID] = useState<any>(null);

    const onTransactionComplete = () => {
        dispatchGlobal({
            type: 'showMessage',
            text: `The Swap was successful. You have received a JollyPack! Please check Unlock page`,
            onConfirm: () => {
                dispatchGlobal({ type: "hideMessage" });
                navigate(routeNames.unlockpack);
            }
        })
    }

    const onTransactionFailed = () => {
        dispatchGlobal({
            type: 'showMessage',
            text: `Transaction failed. Please check transaction logs and report issue.`,
            onConfirm: () => {
                dispatchGlobal({ type: "hideMessage" });
                navigate(routeNames.unlockpack);
            }
        })
    }

    const transactionStatus = useTrackTransactionStatus({
        transactionId: sessionID,
        onSuccess: onTransactionComplete,
        onFail: onTransactionFailed,
        });


    useEffect(() => {
        if ((!spaceRobot && robots && robots.length > 0) || (spaceRobot?.collection != collectionGeneral.identifier)) {
            dispatchGlobal({
                type: "setSpaceRobot", spaceRobot: robots[0]
            });
        }
    }, [robots, spaceRobot]);

    const handleOnMoveSlideSpaceRobots = (index: number) => {
        dispatchGlobal({
            type: "setSpaceRobot", spaceRobot: robots[index]
        });
    };
    

    function swapJollyBot() {
        if (spaceRobot) {
            const response = ContractSpaceRobots.swap(
                spaceRobot.identifier,
                address,
                1
              ).then((response) => {
                dispatchGlobal({ type: "hideMessage" });
                console.log(JSON.stringify(response));
                setSessionID(response.sessionId);

              });
        } else {
            console.log('Nothing to be swapped');
        }
    }

    const handleOnSwap = () => {
        dispatchGlobal({
            type: 'showMessage',
            text: `Are you sure that you want to swap ${spaceRobot?.name} for a JollyPack?`,
            onConfirm: swapJollyBot,
            onCancel: () => {
                dispatchGlobal({type: 'hideMessage'});
            }
        });
    };

    return (
        <div className="container upgrade mt-md-5">
            <div className="row pt-2 pt-md-5">
                <div className="col-12 col-md text-center">
                    { spaceRobot ? 
                        <div className='mb-2 mb-md-4'><span className='title'>{spaceRobot.name}</span></div> 
                        :
                        <div className='mb-2 mb-md-4'><span className='title'>{"//"}: SPACE ROBOT NOT FOUND</span></div> 
                    }
                    <Pager
                        initialSize = {robots.length}
                        index = {spaceRobot && robots.map(nft => nft.identifier).indexOf(spaceRobot.identifier)}
                        onMove={handleOnMoveSlideSpaceRobots}
                    >
                        { spaceRobot ? 
                            <SpaceRobot robot={spaceRobot}/> 
                            :
                            <SpaceRobotNotFound/> 
                        }
                    </Pager>
                    <Desktop>
                        <div className='row pb-4 mt-4'>
                            <div className='col'></div>
                            <div className='col d-flex justify-content-center'>
                                <>
                                    {spaceRobot ?
                                        <Button label='SWAP' className='button-upgrade' onClick={handleOnSwap}/>
                                        :
                                        <Button label='SWAP' className='button-upgrade disabled-link' onClick={handleOnSwap}/>
                                    }
                                </>
                            </div>
                            <div className='col d-flex justify-content-start align-items-center ps-0'>
                                <Tooltip 
                                    text='Use this to swap your JollyBot into a JollyBox'
                                />
                            </div>
                        </div>
                    </Desktop>
                    <Mobile>
                    <div className="row my-4">
                    <div className="col pe-1"></div>
                    <div className="col d-flex justify-content-center">
                        {spaceRobot ? (
                        <Button
                            label="SWAP"
                            className="button-upgrade"
                            onClick={handleOnSwap}
                        />
                        ) : (
                        <Button
                            label="SWAP"
                            className="button-upgrade disabled-link"
                            onClick={handleOnSwap}
                        />
                        )}
                    </div>
                    <div className="col d-flex justify-content-start align-items-center ps-1">
                        <Tooltip text="Use this to swap your JollyBot into a JollyPack" />
                    </div>
                    </div>
                </Mobile>
                </div>
                {/* <div className="col-12 col-md text-center pb-4">

                </div> */}
            </div>
        </div>
    );
};

export default Swap;

