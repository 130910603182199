import React, { useEffect, useMemo, useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AttackIcon } from "assets/icon/attack.svg";
import { ReactComponent as DefenseIcon } from "assets/icon/defense.svg";
import { NFTType, getCollection } from "cli/ElrondAPI";
import { createTemplate } from "cli/TemplateAPI";
import Button from "components/Button";
import Desktop from "components/Desktop";
import Mobile from "components/Mobile";
import Pager from "components/Pager";
import RobotComposition from "components/RobotComposition";
import Switch from "components/Switch";
import Tooltip from "components/Tooltip";
import Traits from "components/Traits";
import { collectionSpaceRobots, collectionRoboParts, collectionGeneral, tags } from "config";
import {
  useDispatch as useGlobalDispatch,
  useContext as useGlobalContext,
} from "context";
import { routeNames } from "routes";
import {
  getExternalImageOfNFT,
  getAttributeFromNFT,
  parseAttributes,
  ROBOT_ATTRIBUTE_ATTACK,
  ROBOT_ATTRIBUTE_DEFENSE,
  ROBOPART_ATTRIBUTE_ATTACK,
  ROBOPART_ATTRIBUTE_DEFENSE,
  ROBOPART_ATTRIBUTE_KIND,
  getAttackDefense,
} from "utils";
import { useContext } from "../Context";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import { network } from "config";

const SpaceRobot = ({
  robot,
  roboParts = {},
}: {
  robot: NFTType;
  roboParts?: { [key: string]: NFTType };
}) => {
  return (
    <div className="robot-slide">
      <div className="image">
        <RobotComposition
          spaceRobot={robot}
          roboParts={Object.values(roboParts)}
        />
      </div>
    </div>
  );
};

const SpaceRobotNotFound = () => {
  return (
    <div className="robot-slide">
      <div className="image">
        <div
          className="robot-composition"
          style={{ position: "relative", display: "inline-block" }}
        >
          <div className="image not-found">
            <img
              className="overlayed"
              src="/assets/robopart-not-found.jpg"
              style={{ zIndex: 10 }}
            />
            <div className="button-buy">
              <Button
                label="BUY A SPACE ROBOT"
                href={"https://xoxno.com/collection/SRC-27d8ff"}
                target="_BLANK"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RoboPart = ({ nft }: { nft: NFTType }) => {
  const image = getExternalImageOfNFT(nft);
  return (
    <div className="robopart-slide">
      <div className="image">
        <img src={image} />
      </div>
    </div>
  );
};

const RoboPartNotFound = () => {
  return (
    <div className="robopart-slide">
      <div className="image not-found">
        <img src="/assets/robopart-not-found.jpg" />
        <div className="button-buy">
          <Button
            label="BUY A ROBOPART"
            href={"https://xoxno.com/collection/SRP-ec2514"}
            target="_BLANK"
          />
        </div>
      </div>
    </div>
  );
};

const RoboPartTags = () => {
  const roboPartsTagsItems = tags.filter((item) => item.name != "Robot");
  const {
    state: { roboPartCurrent, roboPartsLocked, robotPartsFilter },
    dispatch,
  } = useContext();
  const [roboPartCurrentType] =
    (roboPartCurrent &&
      getAttributeFromNFT({
        nft: roboPartCurrent,
        attributes: [ROBOPART_ATTRIBUTE_KIND],
      })) ??
    [];

  const handleOnclick = (type: string) => {
    dispatch({
      type: "setRobotPartsFilter",
      filter: robotPartsFilter != type ? type : undefined,
    });
    const robopart = roboPartsLocked && roboPartsLocked[type];
    dispatch({
      type: "setRoboPartCurrent",
      robopart,
    });
  };
  return (
    <div className="row d-flex justify-content-center">
      {roboPartsTagsItems.map((tag, index) => {
        const activateRoboPartinSelector =
          roboPartCurrentType == tag.name ||
          (robotPartsFilter && tag.name == robotPartsFilter);
        const className = `image d-flex aling-items-center justify-content-center ${
          activateRoboPartinSelector ? "active" : ""
        }`;
        const isLocked = roboPartsLocked && roboPartsLocked[tag.name];
        const image = isLocked ? tag.imageRemark : tag.image;
        return (
          <div
            key={index}
            className="robopart-tag col-auto text-center p-1 p-sm-2"
          >
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleOnclick(tag.name);
              }}
            >
              <div className={className}>
                <img src={image} />
              </div>
            </a>
            <p className="label mt-1">{tag.label}</p>
          </div>
        );
      })}
    </div>
  );
};

function predicateByCollection(collection: string) {
  return (nft: NFTType) => nft.collection == collection;
}

interface UpgradePropsType {
  nfts: Array<NFTType>;
}
const Upgrade = ({ nfts }: UpgradePropsType) => {
  const robots = useMemo(
    () => nfts.filter(predicateByCollection(collectionSpaceRobots.identifier)),
    [nfts],
  );
  const accountRoboParts = useMemo(
    () => nfts.filter(predicateByCollection(collectionRoboParts.identifier)),
    [nfts],
  );
  const {
    state: {
      roboPartCurrent,
      roboPartsLocked,
      roboPartsShowAll,
      robotPartsFilter,
    },
    dispatch,
  } = useContext();

  const navigate = useNavigate();

  const dispatchGlobal = useGlobalDispatch();
  const { spaceRobot } = useGlobalContext();
  const { address } = useGetAccountInfo();
  const [roboParts, setRoboParts] = useState<Array<NFTType>>(accountRoboParts);
  const [roboPartsFiltered, setRoboPartsFiltered] =
    useState<Array<NFTType>>(accountRoboParts);

  const isLockedRoboPartShown =
    roboPartsLocked &&
    Object.values(roboPartsLocked).filter(
      (item) => item.identifier == roboPartCurrent?.identifier,
    ).length > 0;
  const [spaceRobotAttack, spaceRobotDefense] = (spaceRobot &&
    getAttributeFromNFT<number>({
      nft: spaceRobot,
      attributes: [ROBOT_ATTRIBUTE_ATTACK, ROBOT_ATTRIBUTE_DEFENSE],
    })) ?? [0, 0];
  const [roboPartCurrentAttack, roboPartCurrentDefense] = (roboPartCurrent &&
    getAttributeFromNFT<number>({
      nft: roboPartCurrent,
      attributes: [ROBOPART_ATTRIBUTE_ATTACK, ROBOPART_ATTRIBUTE_DEFENSE],
    })) ?? [0, 0];

  const { attack: upgradeAttack, defense: upgradeDefense } = getAttackDefense({
    spaceRobot,
    roboPartsUpgrade: roboPartsLocked ? Object.values(roboPartsLocked) : [],
  });

  useEffect(() => {
    if (roboPartsShowAll) {
      getCollection({
        apiAddress: network.apiAddress,
        timeout: 3000,
        collection: collectionRoboParts.identifier,
        size: collectionRoboParts.expectedSize,
      }).then(({ data }) => {
        setRoboParts(data);
      });
    } else {
      setRoboParts(accountRoboParts);
    }
  }, [roboPartsShowAll, accountRoboParts]);

  useEffect(() => {
    if (robotPartsFilter) {
      const filtered = roboParts.filter((robopart) => {
        const [type] = getAttributeFromNFT({
          nft: robopart,
          attributes: [ROBOPART_ATTRIBUTE_KIND],
        });
        return robotPartsFilter == type;
      });
      setRoboPartsFiltered(filtered);
    } else {
      setRoboPartsFiltered(roboParts);
    }
  }, [roboParts, robotPartsFilter]);

  useEffect(() => {
    if ((!spaceRobot && robots && robots.length > 0) || (spaceRobot?.collection == collectionGeneral.identifier)) {
      dispatchGlobal({
        type: "setSpaceRobot",
        spaceRobot: robots[0],
      });
    }
  }, [robots, spaceRobot]);

  useEffect(() => {
    if (!roboPartCurrent && roboPartsFiltered && roboPartsFiltered.length > 0) {
      dispatch({
        type: "setRoboPartCurrent",
        robopart: roboPartsFiltered[0],
      });
    }
  }, [roboPartsFiltered]);

  const handleOnMoveSlideSpaceRobots = (index: number) => {
    dispatchGlobal({
      type: "setSpaceRobot",
      spaceRobot: robots[index],
    });
  };

  const handleOnSwitchAllRoboParts = (value: boolean) => {
    dispatch({
      type: "setRoboPartsShowAll",
      value,
    });
    dispatch({
      type: "setRoboPartCurrent",
      robopart: undefined,
    });
  };

  const handleOnMoveSlideRoboParts = (index: number) => {
    dispatch({
      type: "setRoboPartCurrent",
      robopart: roboPartsFiltered[index],
    });
  };

  const handleLockRoboPart = () => {
    if (!roboPartCurrent) return;

    const attributes = parseAttributes(roboPartCurrent);
    const kind = attributes[ROBOPART_ATTRIBUTE_KIND];
    if (!kind) {
      console.error(
        'Missing attribute "{}" in RoboPart "{}"',
        ROBOPART_ATTRIBUTE_KIND,
        roboPartCurrent.identifier,
      );
      return;
    }

    dispatch({
      type: "lockRoboPart",
      kind: kind,
      robopart: roboPartCurrent,
    });
  };

  const handleUnLockRoboPart = () => {
    if (!roboPartCurrent) return;

    const attributes = parseAttributes(roboPartCurrent);
    const kind = attributes[ROBOPART_ATTRIBUTE_KIND];
    dispatch({
      type: "unlockRoboPart",
      kind: kind,
    });
  };

  function saveTemplate() {
    if (spaceRobot && roboPartsLocked) {
      const template = {
        nft: spaceRobot,
        spaceRobot: spaceRobot?.identifier,
        name: spaceRobot?.name,
        roboParts: Object.values(roboPartsLocked).map(
          (part) => part.identifier,
        ),
        roboPartsNFTs: Object.values(roboPartsLocked).map((part) => part),
      };
      createTemplate({ address, template }).then(() => {
        dispatchGlobal({
          type: "showMessage",
          title: "Template created successfully",
          text: `The template for ${spaceRobot?.name} has been created successfully`,
          onHide: () => {
            navigate(location.pathname);
          },
        });
      });
    } else {
      console.error("Nothing to be saved");
    }
  }

  const handleOnSaveTemplate = () => {
    dispatchGlobal({
      type: "showMessage",
      text: `Are you sure that you want to create a template for ${spaceRobot?.name}?`,
      onConfirm: saveTemplate,
      onCancel: () => {
        dispatchGlobal({ type: "hideMessage" });
      },
    });
  };

  return (
    <div className="container upgrade mt-md-5">
      <div className="row pt-2 pt-md-5">
        <div className="col-12 col-md text-center">
          {spaceRobot ? (
            <div className="mb-2 mb-md-4">
              <span className="title">{spaceRobot.name}</span>
            </div>
          ) : (
            <div className="mb-2 mb-md-4">
              <span className="title">{"//"}: SPACE ROBOT NOT FOUND</span>
            </div>
          )}
          <Pager
            initialSize={robots.length}
            index={
              spaceRobot &&
              robots.map((nft) => nft.identifier).indexOf(spaceRobot.identifier)
            }
            onMove={handleOnMoveSlideSpaceRobots}
          >
            {spaceRobot ? (
              <SpaceRobot robot={spaceRobot} roboParts={roboPartsLocked} />
            ) : (
              <SpaceRobotNotFound />
            )}
          </Pager>
          <Mobile>
            <div className="mt-2">
              <RoboPartTags />
            </div>
          </Mobile>
          <Desktop>
            <div className="mt-2">
              {spaceRobot && (
                <div className="row">
                  <div className="col"></div>
                  <div className="col">
                    <Traits
                      attack={spaceRobotAttack}
                      defense={spaceRobotDefense}
                      classNames={["muted"]}
                    />
                    <div className="py-2 d-flex align-items-center justify-content-center">
                      <div
                        style={{
                          width: "199px",
                          height: "0px",
                          border: "2px solid #FFFFFF",
                        }}
                      />
                    </div>
                    <Traits attack={upgradeAttack} defense={upgradeDefense} />
                  </div>
                  <div className="col d-flex justify-content-start align-items-center">
                    <Tooltip text="Before and After<br />Total Attack (Red) and Total Defense (Green) values are updated<br />after each new RoboPart is locked in" />
                  </div>
                </div>
              )}
            </div>
          </Desktop>
          <Desktop>
            <div className="row pb-4 mt-4">
              <div className="col"></div>
              <div className="col d-flex justify-content-center">
                <>
                  {spaceRobot &&
                  roboPartsLocked &&
                  Object.keys(roboPartsLocked).length > 0 ? (
                    <Button
                      label="SAVE TEMPLATE"
                      className="button-upgrade"
                      onClick={handleOnSaveTemplate}
                    />
                  ) : (
                    <Button
                      label="SAVE TEMPLATE"
                      className="button-upgrade disabled-link"
                      onClick={handleOnSaveTemplate}
                    />
                  )}
                </>
              </div>
              <div className="col d-flex justify-content-start align-items-center ps-0">
                <Tooltip text="In Space Robots NFT upgrade Beta <br/>, you can save a Template of the selected RoboParts<br/>for Space Robots NFT Upgrade final version" />
              </div>
            </div>
          </Desktop>
        </div>
        <div className="col-12 col-md text-center pb-4">
          {roboPartCurrent ? (
            <div className="mb-2 mb-md-4">
              <span className="title">{roboPartCurrent.name}</span>
            </div>
          ) : (
            <div className="mb-2 mb-md-4">
              <span className="title">{"//"}: ROBOPART NOT FOUND</span>
            </div>
          )}
          <Pager
            initialSize={roboPartsFiltered.length}
            index={
              roboPartCurrent &&
              roboPartsFiltered
                .map((nft) => nft.identifier)
                .indexOf(roboPartCurrent.identifier)
            }
            onMove={handleOnMoveSlideRoboParts}
          >
            {roboPartCurrent ? (
              <RoboPart nft={roboPartCurrent} />
            ) : (
              <RoboPartNotFound />
            )}
          </Pager>
          {roboPartCurrent && (
            <div className="row mt-2">
              <div className="col"></div>
              <div className="col-auto">
                <Traits
                  attack={roboPartCurrentAttack}
                  defense={roboPartCurrentDefense}
                />
              </div>
              <div className="col d-flex justify-content-start align-items-center">
                <Tooltip text="(Red) Attack and (Green) Defense values of the selected RoboPart" />
              </div>
            </div>
          )}
          <Desktop>
            <div className="mt-2">
              <RoboPartTags />
            </div>
          </Desktop>
          <div className="row pt-2 pt-md-4">
            <div className="col pe-0"></div>
            <div className="col-auto d-flex justify-content-center">
              {roboPartCurrent ? (
                isLockedRoboPartShown ? (
                  <Button
                    label="UNLOCK"
                    className="button-lock"
                    onClick={handleUnLockRoboPart}
                  />
                ) : (
                  <Button
                    label="LOCK"
                    className="button-lock"
                    onClick={handleLockRoboPart}
                  />
                )
              ) : (
                <Button
                  label="LOCK"
                  className="button-lock disabled-link"
                  onClick={undefined}
                />
              )}
            </div>
            <div className="col d-flex justify-content-start align-items-center ps-0">
              {!isLockedRoboPartShown && (
                <Tooltip text="Clicking Lock will activate a selected RoboPart on your Space Robot NFT" />
              )}
            </div>
          </div>
          <Desktop>
            <div className="all-roboparts row p-2 pt-md-5 mt-2">
              <div className="col"></div>
              <div className="col-auto d-flex justify-content-center align-items-center">
                <Switch
                  onChange={handleOnSwitchAllRoboParts}
                  checked={roboPartsShowAll ?? false}
                />
              </div>
              <div className="col d-flex justify-content-start align-items-center ps-0">
                <Tooltip text="Toggling to All RoboParts enables all items in the collection" />
              </div>
              <div className="col-md-12 order-1 order-md-2 mt-md-2 d-flex justify-content-end justify-content-md-center">
                <span>All RoboParts</span>
              </div>
            </div>
          </Desktop>
          <Mobile>
            <div className="all-roboparts row p-2 pt-md-5 mt-2">
              <div className="col-7 d-flex justify-content-end justify-content-md-center">
                <span>All RoboParts</span>
              </div>
              <div className="col-auto d-flex justify-content-start align-items-center ps-0">
                <Switch
                  onChange={handleOnSwitchAllRoboParts}
                  checked={roboPartsShowAll ?? false}
                />
              </div>
              <div className="col-2 d-flex justify-content-start align-items-center ps-0">
                <Tooltip text="Toggling to All RoboParts enables all items in the collection" />
              </div>
            </div>
          </Mobile>
          <Mobile>
            {spaceRobot && (
              <div className="row">
                <div className="col pe-0"></div>
                <div className="col">
                  <div className="row">
                    <div className="col border-end ps-4">
                      <div className="traits">
                        <div className="row">
                          <div className="col-12 col-md-6 d-flex align-items-center justify-content-end pb-2">
                            <AttackIcon />
                            <span className="points ms-3">
                              {spaceRobotAttack}
                            </span>
                          </div>
                          <div className="col-12 col-md-6 d-flex align-items-center justify-content-end pb-2">
                            <DefenseIcon />
                            <span className="points ms-3">
                              {spaceRobotDefense}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="traits">
                        <div className="row">
                          <div className="col-12 col-md-6 d-flex align-items-center justify-content-start pb-2">
                            <span className="points red">{upgradeAttack}</span>
                          </div>
                          <div className="col-12 col-md-6 d-flex align-items-center justify-content-start pb-2">
                            <span className="points green">
                              {upgradeDefense}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col d-flex justify-content-start align-items-center ps-0">
                  <Tooltip text="Before and After Total Attack (Red) and Total Defense (Green) values are updated after each new RoboPart is locked in" />
                </div>
              </div>
            )}
          </Mobile>
          <Mobile>
            <div className="row my-4">
              <div className="col pe-1"></div>
              <div className="col d-flex justify-content-center">
                {roboPartsLocked && Object.keys(roboPartsLocked).length > 0 ? (
                  <Button
                    label="SAVE TEMPLATE"
                    className="button-upgrade"
                    onClick={handleOnSaveTemplate}
                  />
                ) : (
                  <Button
                    label="SAVE TEMPLATE"
                    className="button-upgrade disabled-link"
                    onClick={handleOnSaveTemplate}
                  />
                )}
              </div>
              <div className="col d-flex justify-content-start align-items-center ps-1">
                <Tooltip text="In Space Robots NFT upgrade Beta <br/>, you can save a Template of the selected RoboParts<br/>for Space Robots NFT Upgrade final version" />
              </div>
            </div>
            <div className="row my-4">
              <div className="col d-flex justify-content-center">
                <Button
                  label="MY TEMPLATES"
                  className="button-upgrade"
                  href={routeNames.templates}
                />
              </div>
            </div>
          </Mobile>
        </div>
      </div>
    </div>
  );
};

export default Upgrade;
