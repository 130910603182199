import React from "react";
import { NFTType } from "cli/ElrondAPI";
import Slider from "components/Slider";
import { collections } from "config";
import { getExternalImageOfNFT } from 'utils';
import RoboPart from "../RoboPart";
import "./styles.scss";
import Button from "components/Button";
interface RoboPartNFT extends NFTType {
    link?: string;
}
interface RoboPartsType {
    NFTs: RoboPartNFT[]
    onUpgrade: (identifier: NFTType) => void
    onSwap: (identifier: NFTType) => void
    className?: string;
}

function isUpgradeable(nft: RoboPartNFT): boolean {
    const { upgradeable } = collections.filter(col=>col.identifier === nft.collection)[0];
    return upgradeable;
}

function isSwapable(nft: RoboPartNFT): boolean {
    const { swapable } = collections.filter(col=>col.identifier === nft.collection)[0];
    return swapable!;
}

const RoboParts = ({NFTs, onUpgrade, onSwap, className}: RoboPartsType) => {
    const classNames = ["container", "roboparts"];
    classNames.push(className ?? "");

    const rowsBySlide = 2;
    const items = [];

    const handleOnUpgrade = (nft: NFTType) => {
        onUpgrade(nft);
    };

    const handleOnSwap = (nft: NFTType) => {
        onSwap(nft);
    };

    for (let slideIndex=0; slideIndex < NFTs.length; slideIndex +=rowsBySlide) {
        const slideRows = NFTs.slice(slideIndex, slideIndex + rowsBySlide);
        items.push(
            <div key={slideIndex} className="row">
                {
                slideRows.map((nft, count) => {
                    const externalImage = getExternalImageOfNFT(nft);
                    return (
                        <div key={count} className="col d-flex justify-content-center">
                            <RoboPart
                                name={nft.name}
                                image={externalImage}
                                link={nft.link}
                                onUpgrade={isUpgradeable(nft)? () => handleOnUpgrade(nft): undefined}
                                onSwap={isSwapable(nft)? () => handleOnSwap(nft): undefined}
                            />
                        </div>
                    );
                })
                }
            </div>
        );
    }

    if (items.length == 0) {
        items.push(
            <div key={0} className="row">
                <div key={0} className="col d-flex justify-content-center">
                    <div className="robopart">
                        <div className="roboImage image not-found" >
                            <img src="/assets/robopart-not-found.jpg" />
                            <div className='button-buy'>
                                <Button label="BUY A ROBOPART" href={"https://xoxno.com/collection/SRP-ec2514"} target="_BLANK" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={classNames.join(" ")}>
            <Slider 
                items={items}
                perView={4}
                breakpoints={{1400: 3, 1200: 2, 992: 2, 576: 2, 544: 1}} className="mt-4 p-5"
            />
        </div>
    );
};

export default RoboParts;