import axios from "axios";
import { templateApiAddress } from "config";

export interface Template  {
    id?: string
    spaceRobot: string
    roboParts: Array<string>
}

async function createTemplate({address, template}: {address: string, template: Template}): Promise<any> {
    return axios.post(
        `${templateApiAddress}/template/${address}`,
        template
    );
}

async function getTemplates(address: string): Promise<Array<Template>> {
    return axios.get(
        `${templateApiAddress}/template/${address}`
    ).then(({data}) => data);
}

async function deleteTemplate({address, templateId}: {address: string, templateId: string}) {
    return axios.delete(
        `${templateApiAddress}/template/${address}/${templateId}`
    );
}

export {
    createTemplate,
    getTemplates,
    deleteTemplate
};