import React from "react";
import { DappProvider } from "@multiversx/sdk-dapp/wrappers/DappProvider/DappProvider";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { network, walletConnectV2ProjectId } from "config";
import { ContextProvider } from "context";
import Layout from "./components/Layout";
import PageNotFound from "./components/PageNotFound";
import "./assets/sass/theme.scss";
import routes from "./routes";
import {
  NotificationModal,
  SignTransactionsModals,
  TransactionsToastList,
} from "@multiversx/sdk-dapp/UI";
import { ContextProvider as ContextProviderInventory } from "pages/Inventory/Context";

export default function App() {
  return (
    <BrowserRouter>
      <DappProvider
        environment={network.id}
        customNetworkConfig={{
          name: "customConfig",
          apiTimeout: 6000,
          walletConnectV2ProjectId,
        }}
      >
        <ContextProvider>
          <Layout>
            <TransactionsToastList successfulToastLifetime={10000} />
            <NotificationModal />
            <SignTransactionsModals />
            <Routes>
              {routes.map((route, i) => (
                <Route
                  path={route.path}
                  key={route.path + i}
                  element={route.component()}
                />
              ))}
              <Route element={<PageNotFound />} />
            </Routes>
          </Layout>
        </ContextProvider>
      </DappProvider>
    </BrowserRouter>
  );
}
